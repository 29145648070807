import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
/* import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu'; */

export default function HeaderAppBar() {
  const { t } = useTranslation();
  return (
    <Box sx={{ flexGrow: 1, width: '100%', zIndex: 10, top: 0 }}>
      <AppBar position="sticky">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <img id='mainlogo' alt='3d-berlin Logo' src='./3d-berlin-Logo.png'></img>
          <Typography variant="h6" align='left' component="div" sx={{ flexGrow: 1, fontWeight: 800 }}>
            {t('title')}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}