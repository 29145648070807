import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
/* import translation_en from './en/translation_en.json';
import translation_de from './de/translation_de.json'; */

export const resources = {
    en: {
        translation: {
            title: "Demo-Gallery",
            search: "Search",
            dataconnection: "Data connection",
            products: "Products",
            proj_descrpt: "Project description",
            descrpt: "Description",
            startpoints: "Startpoints",
            copytoclip: "Copy to clipboard",
            service: "Service is active",
            visible: "The project is publicly visible",
            zeroprods: "No products implemented",
            industry: "Industry",
            copylink: "Copy Link to this site"
        }
    },
    de: {
        translation: {
            title: "Demo-Gallery", 
            search: "Suche",           
            dataconnection: "Datenanbindung",
            products: "Produkte",
            proj_descrpt: "Projektbeschreibung",
            descrpt: "Beschreibung",
            startpoints: "Startpunkte",
            copytoclip: "In die Zwischenablage kopieren",
            service: "Service ist aktiv",
            visible: "Das Projekt ist öffentlich sichtbar",
            zeroprods: "Keine Produkte implementiert",
            industry: "Branche",
            copylink: "Link zu dieser Seite kopieren"
        }
    }
};

let lang: string = (navigator.language).substring(0, 2);
if (lang !== "de") {
    lang = "en";
}

i18next.use(initReactI18next).init({
    /* lng: 'en', */ // if you're using a language detector, do not define the lng option
    lng: lang,
    debug: false,
    resources: resources,
    preload: ['de', 'en'],
    /* ns: ['translation_de', 'translation_en'] */
});