import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
/* import type { RootState } from '../store' */
import { IGuide3DMenuServiceCommonInterfacesPointItemElementType, ProjectsController } from '../../interfaces/interfaceProjectsController';

// Define a type for the slice state
interface CurrentProjectsController {
    value: ProjectsController
}

// Define the initial state using that type
const initialState: CurrentProjectsController = {
    value: {
        guiInternStatus: "unset",
        currentProjects: [],
        allProjects: [],
        detailProject: undefined,
        detailDisplay: false,
        bIntern: false,
        sTerm: "",
        branches: [],
        currentBranch: ""
    }
}

export const currentProjectsControllerSlice = createSlice({
    name: 'setCurrentProjectsController',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setCurrentProjectsController: (state, action: PayloadAction<ProjectsController>) => {
            state.value = action.payload;
        },
        setCurrentProjectsController_guiInternStatus: (state, action: PayloadAction<string>) => {
            state.value.guiInternStatus = action.payload;
        },
        setCurrentProjectsController_currentProjects: (state, action: PayloadAction<IGuide3DMenuServiceCommonInterfacesPointItemElementType[]>) => {
            state.value.currentProjects = action.payload;
        },
        setCurrentProjectsController_allProjects: (state, action: PayloadAction<IGuide3DMenuServiceCommonInterfacesPointItemElementType[]>) => {
            state.value.allProjects = action.payload;
        },
        setCurrentProjectsController_detailDisplay: (state, action: PayloadAction<boolean>) => {
            state.value.detailDisplay = action.payload;
        },
        setCurrentProjectsController_bIntern: (state, action: PayloadAction<boolean>) => {
            state.value.bIntern = action.payload;
        },
        setCurrentProjectsController_sTerm: (state, action: PayloadAction<string>) => {
            state.value.sTerm = action.payload;
        },
        setCurrentProjectsController_detailProject: (state, action: PayloadAction<IGuide3DMenuServiceCommonInterfacesPointItemElementType>) => {
            state.value.detailProject = action.payload;
        },
        setCurrentProjectsController_branches: (state, action: PayloadAction<string[]>) => {
            state.value.branches = action.payload;
        },
        setCurrentProjectsController_currentBranch: (state, action: PayloadAction<string>) => {
            state.value.currentBranch = action.payload;
        },
    },
})

export const { setCurrentProjectsController } = currentProjectsControllerSlice.actions
export const { setCurrentProjectsController_guiInternStatus } = currentProjectsControllerSlice.actions
export const { setCurrentProjectsController_currentProjects } = currentProjectsControllerSlice.actions
export const { setCurrentProjectsController_allProjects } = currentProjectsControllerSlice.actions
export const { setCurrentProjectsController_detailProject } = currentProjectsControllerSlice.actions
export const { setCurrentProjectsController_detailDisplay } = currentProjectsControllerSlice.actions
export const { setCurrentProjectsController_bIntern } = currentProjectsControllerSlice.actions
export const { setCurrentProjectsController_sTerm } = currentProjectsControllerSlice.actions
export const { setCurrentProjectsController_branches } = currentProjectsControllerSlice.actions
export const { setCurrentProjectsController_currentBranch } = currentProjectsControllerSlice.actions

// Other code such as selectors can use the imported `RootState` type

export default currentProjectsControllerSlice.reducer