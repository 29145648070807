
/// <reference path="./d.ts/Guide3DQueryService_V1.0.0.d.ts"/>
import React, { useCallback } from 'react';
import './App.css';
import { useAppDispatch, useAppSelector } from './components/hooks';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HeaderAppBar from './components/headerBar';
import FullWidthGrid from './components/customGrid';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
/* import Autocomplete from '@mui/material/Autocomplete'; */
import { ProjectsToMenuConverter } from './components/ProjectsToMenuConverter';
import { setCurrentProjectsController_allProjects, setCurrentProjectsController_bIntern, setCurrentProjectsController_currentBranch, setCurrentProjectsController_currentProjects, setCurrentProjectsController_detailProject, setCurrentProjectsController_guiInternStatus, setCurrentProjectsController_sTerm } from './components/slices/projectsControllerSlice';
import { getI18n, useTranslation } from 'react-i18next';
import './i18n/config';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
/* import { TextField } from '@mui/material'; */

let currentService: Guide3DMenuService;
let nReadyCounter: number = 0;
let searchUrlValue: string = "";
let bInit: boolean = true;
let bIntern: boolean = false;

function App() {
  const dispatch = useAppDispatch();
  /* const [bIntern, setbIntern] = React.useState(false); */
  const [bFirstStart, setbFirstStart] = React.useState(true);
  const [projSearchInit, setProjSearchInit] = React.useState(true);
  const [searchParams, setSearchParams] = React.useState([""]);
  const branches = useAppSelector((state) => state.projectsController.value.branches);
  const [currentBranch, setCurrentBranch] = React.useState("");

  const mainTheme = createTheme({
    palette: {
      primary: {
        main: '#C9D30F',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#CCCCCC',
        light: '#F2F2F2',
        contrastText: '#333333',
        dark: '#030E12',
      },
      background: {
        default: '#fff',
        paper: '#fff',
      },
      text: {
        primary: '#666666',
        secondary: '#666666',
        disabled: '#0000ff',
        /* hint: '#24A3D3', */
      },
    },
    shape: {
      borderRadius: 2
    },
    typography: {
      fontFamily: [
        'OPENSANS',
        'OPENSANSMEDIUM',
      ].join(','),
    }
  });

  const detailDisplay = useAppSelector((state) => state.projectsController.value.detailDisplay);

  const ariaLabel = { 'aria-label': 'description' };

  const _getUrlParameter = (name: string) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(document.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  const _checkForBetaPath = ():boolean => {
    let isOnBetaPath: boolean = false;
    const url: string = document.location.toString();
    if(url.indexOf("beta") !== -1){
      isOnBetaPath = true;
    }
    return isOnBetaPath;
  }



  const _evalToken = (token: string) => {
    let projNo: string = "";
    let decodeb64: string;
    let projectPastfix: string;

    try {
      decodeb64 = window.atob(token);
      // console.log("decodeb64 -> " + decodeb64);
      projectPastfix = decodeb64.substr(3);
      projNo = "100" + projectPastfix;
    } catch (error) {
      projNo = "10001";
    }

    return projNo;
  }

  /* const getURLParameter = (name: string) => {
    return decodeURI((RegExp(name + '=' + '(.+?)(&|$)').exec(window.location.search)||[,null])[1] as string);
  } */

  const hideURLParams = () => {

    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    // Delete the token parameter.
    params.delete("token");

    const baseUrl: string = window.location.href.split('?')[0]
    let constructedUrl: string = "";

    if (params.size > 0) {
      constructedUrl = baseUrl + "?" + params;
    }
    else {
      constructedUrl = baseUrl;
    }
    console.log("constructedUrl: " + constructedUrl);
    window.history.replaceState(null, document.getElementsByTagName("title")[0].innerHTML, constructedUrl);
  }


  const startup = () => {
    if (bFirstStart === true) {
      console.log("STARTUP");
      const interToken: string = _getUrlParameter("token");
      const searchToken: string = _getUrlParameter("search");
      const langToken: string = _getUrlParameter("language");
      const appInBetaPath: boolean = _checkForBetaPath();
      const decodedToken: string = _evalToken(interToken);
      let currentLanguage: string = "en";

      if (langToken === "de" || langToken === "en") {
        currentLanguage = langToken;
      }

      /* console.log("###### searchToken => " + searchToken); */
      // setSearchUrlValue(searchToken);
      searchUrlValue = searchToken;

      if (decodedToken.indexOf("100") === 0) {
        if (decodedToken.indexOf("242") === 3) {
          if (bIntern === false) {
            bIntern = true;
            dispatch(setCurrentProjectsController_bIntern(true));
            window.setTimeout(() => {
              _checkSearchReadiness();
            }, 250);
          }
        }
        else {
          _checkSearchReadiness();
        }
      }


      const i18 = getI18n();
      /* const currentLanguage: string = i18.language; */
      i18.changeLanguage(currentLanguage);
      /* if (userStatus.language !== currentLanguage) {
        i18.changeLanguage(userStatus.language);
      } */

      const oProjectsToMenuConverter = new ProjectsToMenuConverter({
        debug: false, beta: appInBetaPath, onload: (oProjectsToMenuConverterEventLoaded) => {
          oProjectsToMenuConverterEventLoaded.menu.setIconsBaseUrl("./res/png/");
          // setCurrentService(oProjectsToMenuConverterEventLoaded.menu);
          currentService = oProjectsToMenuConverterEventLoaded.menu;
          _checkSearchReadiness();
        }
      });
      oProjectsToMenuConverter.setLanguage(currentLanguage);

      console.log(oProjectsToMenuConverter.toString() + " loaded");
      setbFirstStart(false);
      hideURLParams();
    }
    else {

    }

  }

  const setAllProjectsList = useCallback((oGuide3DMenuService: Guide3DMenuService, bInternMode: boolean, sTerm?: string) => {
    /* console.log("oGuide3DMenuService getLanguage A: " + oGuide3DMenuService.getLanguage());
    oGuide3DMenuService.setLanguage("de");
    console.log("oGuide3DMenuService getLanguage B: " + oGuide3DMenuService.getLanguage()); */
    let oItems: IGuide3DMenuServiceCommonInterfacesPointItemElementType[] = [];

    if (bInternMode) {
      if (sTerm) {
        oItems = oGuide3DMenuService.getPointItems(Guide3DMenuService.MENU_TYPE_START, { "recrusive": true, "case-insensitive": true, "fuzzy-tolerance": 0, "search": { name: sTerm, keywords: sTerm, location: sTerm } });
      } else {
        oItems = oGuide3DMenuService.getPointItems(Guide3DMenuService.MENU_TYPE_START, { "recrusive": true });
      }
    } else {
      if (sTerm) {
        oItems = oGuide3DMenuService.getPointItems(Guide3DMenuService.MENU_TYPE_START, { "recrusive": true, "case-insensitive": true, "fuzzy-tolerance": 0, "search": { name: sTerm, keywords: sTerm, location: sTerm }, "match": { display: true } });
      } else {
        oItems = oGuide3DMenuService.getPointItems(Guide3DMenuService.MENU_TYPE_END, { "recrusive": true, "match": { display: true } })
      }
    }

    if (oItems.length) {
      oItems.reverse().forEach((oItem) => {
        oItem.id = oItem.id.split("-").pop() as string;
        oItem.redirect = Array.from(new Array(JSON.parse(oItem.data as string).products.length));
        oItem.point = String((oItem.location as string[]).join(" / "));
        oItem.location = [(oItem.description as string)];
        delete oItem.description;
      });

      /* setAllLoadedProjects(oItems); */
      dispatch(setCurrentProjectsController_allProjects(oItems));
    }
    /* $("ul#ulProjects").listview("refresh"); */
  }, [dispatch]);

  const setProjectsList = useCallback((oGuide3DMenuService: Guide3DMenuService, bInternMode: boolean, sTerm?: string) => {
    let oItems: IGuide3DMenuServiceCommonInterfacesPointItemElementType[] = [];

    if (bInternMode) {
      if (sTerm) {
        oItems = oGuide3DMenuService.getPointItems(Guide3DMenuService.MENU_TYPE_START, { "recrusive": true, "case-insensitive": true, "fuzzy-tolerance": 0, "search": { name: sTerm, keywords: sTerm, location: sTerm } });
      } else {
        oItems = oGuide3DMenuService.getPointItems(Guide3DMenuService.MENU_TYPE_START, { "recrusive": true });
      }
    } else {
      if (sTerm) {
        oItems = oGuide3DMenuService.getPointItems(Guide3DMenuService.MENU_TYPE_START, { "recrusive": true, "case-insensitive": true, "fuzzy-tolerance": 0, "search": { name: sTerm, keywords: sTerm, location: sTerm }, "match": { display: true } });
      } else {
        oItems = oGuide3DMenuService.getPointItems(Guide3DMenuService.MENU_TYPE_END, { "recrusive": true, "match": { display: true } })
      }
    }

    if (oItems.length) {


      oItems.reverse().forEach((oItem) => {
        oItem.id = oItem.id.split("-").pop() as string;
        oItem.redirect = Array.from(new Array(JSON.parse(oItem.data as string).products.length));
        oItem.point = String((oItem.location as string[]).join(" / "));
        oItem.location = [(oItem.description as string)];
        delete oItem.description;
      });
      /* console.log("oItems[0]: ", oItems[0]); */
      // setSearchParams([]);
      if (sTerm) {
        /* console.log("oItems: ", oItems); */
        searchUrlValue = sTerm;
        const menuSearchparams: string[] = oGuide3DMenuService.retrievePointItemsSearchTerms(oItems, { name: true, keywords: true, location: true }) as string[];
        let count: number = 0;
        let chipArray: string[] = []
        for (const element of menuSearchparams) {
          if (count < 6) {
            if (element.indexOf(sTerm) === 0) {
              chipArray.push(element);
              setSearchParams(chipArray);
              count++;
            }
          }
          else break;
        }
      }
      dispatch(setCurrentProjectsController_sTerm(searchUrlValue));
      dispatch(setCurrentProjectsController_guiInternStatus("public"));
      dispatch(setCurrentProjectsController_currentProjects(oItems));
      if (projSearchInit === true && oItems.length === 1 && (/^1[0-9]{5}$/.test(String(sTerm))) === true) {
        setProjSearchInit(false);
        dispatch(setCurrentProjectsController_detailProject(oItems[0]))
      }
      else {
        setProjSearchInit(false);
      }
    } else {
      /*setProjectsList(oGuide3DMenuService,bInternMode,searchUrlValue);*/
      setSearchParams([""]);
      dispatch(setCurrentProjectsController_sTerm(searchUrlValue));
      dispatch(setCurrentProjectsController_guiInternStatus("public"));
      dispatch(setCurrentProjectsController_currentProjects([]));
    }
  }, [dispatch, projSearchInit]);

  const _checkSearchReadiness = useCallback(() => {
    /* setnReadyCounter(nReadyCounter + 1); */
    nReadyCounter++;
    /* console.log("_checkSearchReadiness CALLED -> " + nReadyCounter + " / searchUrlValue: " + searchUrlValue); */
    if (nReadyCounter === 2) {
      console.log("Projects loaded");
      setAllProjectsList(currentService as Guide3DMenuService, bIntern);
      setProjectsList(currentService as Guide3DMenuService, bIntern, searchUrlValue);

      if (searchUrlValue.length > 0) {
        const inputEl = window.document.getElementById("mainInput") as HTMLInputElement;
        inputEl.value = searchUrlValue;
      }
    }
  }, [setAllProjectsList, setProjectsList]);


  const handleClick = (chipText: string) => {
    /* setSearchUrlValue(chipText); */
    searchUrlValue = chipText;
    const inputEl = window.document.getElementById("mainInput") as HTMLInputElement;
    inputEl.value = chipText;
    if (chipText === "") {
      setSearchParams([chipText])
      inputEl.value = "";
      /* searchUrlValue = ""; */
    }
    setProjectsList(currentService as Guide3DMenuService, bIntern, chipText);
  };

  const handleIndustryChange = (event: SelectChangeEvent) => {
    setCurrentBranch(event.target.value);
    dispatch(setCurrentProjectsController_currentBranch(event.target.value));
  }

  const requestSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value.toLocaleLowerCase();
    if (event.target.value.length === 0) {
      handleClick("");
    }
    else {
      searchUrlValue = enteredName;
      setProjectsList(currentService as Guide3DMenuService, bIntern, enteredName);
    }
  }
  const { t } = useTranslation();

  if (bInit === true) {
    bInit = false;
    startup();
  }
  return (
    <div className="App" >
      {<React.StrictMode>
        <ThemeProvider theme={mainTheme}>
          <ThemeProvider theme={mainTheme}>
            <div className='sticky-container'>
              <HeaderAppBar></HeaderAppBar>
              {!detailDisplay
                ?
                <Box sx={{ padding: "1em", paddingTop: 0, display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap', backgroundColor: '#ffffff', background: '' }}>
                  <SearchIcon sx={{ mr: 1, my: 0.5 }} />
                  <Input id="mainInput" color="secondary" type='text' onChange={event => requestSearch(event as React.ChangeEvent<HTMLInputElement>)} autoCapitalize="none"
                    sx={{ color: "secondary.contrastText", marginRight: "1em", marginTop: '0.7em' }} placeholder={t('search') as string} inputProps={ariaLabel} /* value={searchUrlValue} */
                    endAdornment={
                      (searchUrlValue.length > 0)
                        ?
                        <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                          <ClearIcon onClick={() => handleClick("")} />
                        </InputAdornment>
                        : null
                    }
                  />
                  {branches.length > 0
                    ?
                    <FormControl variant="standard" color="secondary" sx={{ color: "secondary.contrastText", marginRight: "1em", marginTop: '0.7em', minWidth: '8em' }}>
                      <InputLabel id="demo-simple-select-standard-label">Industry</InputLabel>
                      <Select color="secondary" sx={{ color: "secondary.contrastText" }}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={currentBranch}
                        onChange={handleIndustryChange} 
                        label={t('industry')}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {branches.map((element: string, index) => {
                          return <MenuItem key={"industry_ " + index} value={element}>{element}</MenuItem>                        
                        })}
                      </Select>
                    </FormControl>
                    : null
                  }
                  {searchParams.length > 0 && searchParams[0] !== "" ?
                    searchParams.map((text) => {
                      return (<Chip key={'chip_' + text} sx={{ color: "secondary.contrastText", marginRight: "1em", marginTop: '0.7em', boxShadow: 2 }} label={text} onClick={() => handleClick(text)} />)
                    })
                    : null
                  }
                </Box>
                : null
              }
            </div>
            <FullWidthGrid></FullWidthGrid>
          </ThemeProvider>
          {/* <SignIn /> */}
        </ThemeProvider>
      </React.StrictMode>}
    </div>
  );


}

export default App;
