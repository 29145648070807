/*******************************************************************/
/***                                                             ***/
/***   ProjectsToMenuConverter.js                                ***/
/***                                                             ***/
/***   Version   : V1.0.0                                        ***/
/***   Date      : 09.05.2023                                    ***/
/***   Author    : 3d-berlin vr solutions GmbH                   ***/
/***   Copyright : 2023 3d-berlin vr solutions GmbH              ***/
/***   Website   : www.3d-berlin.com                             ***/
/***                                                             ***/
/***    This library is owned by 3d-berlin vr solutions GmbH     ***/
/***        Do not copy or distribute without permission         ***/
/***                                                             ***/
/*******************************************************************/

// tsc --project ./tsconfig.json

// import "./../d.ts/Guide3DLanguageService_V1.0.0";
/* import "./../d.ts/Guide3DMenuService_V1.4.0"; */
// import "./../../src/d.ts/Guide3DMenuService_V1.4.0"


interface IProjectsToMenuConverterOptions {
    "debug"?: boolean;
    "beta"?: boolean;
    "onload"?: (oEvent: IProjectsToMenuConverterEventLoaded) => void;
}

interface IProjectsToMenuConverterEventLoaded {
    "intern": {
        "count": number;
        "cities": string[];
        "countries": string[];
        "languages": string[];
        "industries": string[];
        "products": string[];
        "productsOptions": string[];
        "keywords": string[];
    };
    "extern": {
        "count": number;
        "cities": string[];
        "countries": string[];
        "languages": string[];
        "industries": string[];
        "products": string[];
        "productsOptions": string[];
        "keywords": string[];
    };
    "menu": Guide3DMenuService;
}

interface IServiceCenterProject {
    "id": number;
    "name": string;
    "demo": boolean;
    "public": boolean;
    "service": boolean;
    "city": string;
    "country": string;
    "languages": string[];
    "market": string;
    "referenceUrl": string | null;
    "dataConnection": boolean;
    "keywords": string[];
    "kiosk": {
        "platform": string;
        "technology": string;
    };
    "productsData": [{
        "name": string;
        "url": string;
    }];
}

interface IConverterProject {
    "validated"?: boolean;
    "project": number;
    "public": boolean;
    "service": boolean;
    "name": string;
    "city": string;
    "country": string;
    "languages": IProjectLanguages;
    "industry": string;
    "reference": string;
    "dataConnection": boolean;
    "products": IProjectProduct[];
    "productsOptions": IProjectProductsOption[];
}

interface IProjectLanguages {
    [code: string]: string;
}

interface IProjectProduct {
    "name": string;
    "type": "easyGuide_kiosk" | "easyGuide_mobile" | "easyGuide_web" | "easyGuide_print" | "easyMaps_mobile" | "easyMaps_kiosk" | "carFinder" | "mapsEngine" | "custom" | "endOfLife";
    "onPremises": boolean;
    "links"?: {
        "url": string;
        "app"?: string;
        "beta"?: string;
        "description"?: string;
        "status"?: string;
        "kioskList"?: string;
        "mobileSimulator"?: string;
        "webConfigurator"?: string;
        "qrCodeGenerator"?: string;
    };
    "kioskPlatform"?: string;
}

interface IProjectProductsOption {
    "name": string;
    "type": "add-on" | "tool" | "deployment";
    "links"?: {
        "description"?: string;
    };
}

export class ProjectsToMenuConverter {

    // ================================================================== //
    // ====                Static Properties (public)                ==== //
    // ================================================================== //

    // ================================================================== //
    // ====                 Static Methods (public)                  ==== //
    // ================================================================== //

    public static toString(): string {

        return this._sCLASS_NAME;

    }

    public static printDebug(sFunctionName: string, sDebugMessage: string, bDebug: boolean): void {

        if (bDebug) {
            console.log(this.toString() + " @ " + sFunctionName + "(): " + sDebugMessage);
        }
        return;

    }

    public static printError(sFunctionName: string, sErrorMessage: string): void {

        console.error(this.toString() + " @ " + sFunctionName + "(): " + sErrorMessage);
        return;

    }

    public static getUrlEncodedParameters(oParameters: { [key: string]: string } = {}): string {

        return Object.keys(oParameters).filter((sKey) => {
            return oParameters[sKey] !== undefined;
        }).map((sKey) => {
            return encodeURIComponent(sKey) + "=" + encodeURIComponent(oParameters[sKey]);
        }).join("&");

    }

    // ================================================================== //
    // ====               Static Properties (private)                ==== //
    // ================================================================== //

    private static _oVERSION: number[] = [1, 0, 0];
    private static _sCLASS_NAME: string = "'Service-Center Projects' to 'Guide3D Menu-Service' V" + this._oVERSION.join(".");

    // ================================================================== //
    // ====                 Static Methods (private)                 ==== //
    // ================================================================== //

    // ================================================================== //
    // ====                      Public fields                       ==== //
    // ================================================================== //

    // ================================================================== //
    // ====                      Private fields                      ==== //
    // ================================================================== //

    private readonly _bDebug: boolean;
    private _bWarn: string = "false";

    private readonly _oGuide3DMenuService: Guide3DMenuService = new Guide3DMenuService({ project: 999999, language: "en" });

    private readonly _sProjectsDataFeedUrlLive: string = "https://service.3d-berlin.com/demogallery/ProjectsData.json";
    private readonly _sProjectsDataFeedUrlBeta: string = "https://service.3d-berlin.com/demogallery/ProjectsData.beta.json";

    private readonly _oMapProductsWebsiteLinks: Map<string, string> = new Map([
        ["easyGuide_kiosk", "https://3d-berlin.com/en/products/easyguide-en/easyguide-kiosk-retail-en"],
        ["easyGuide_mobile", "https://3d-berlin.com/en/products/easyguide-en/easyguide-mobile-en"],
        ["easyGuide_web", "https://3d-berlin.com/en/products/easyguide-en/easyguide-web-en"],
        ["easyGuide_print", "https://3d-berlin.com/en/products/easyguide-en/easyguide-print-en"],
        ["easyMaps_mobile", "https://3d-berlin.com/en/products/easymaps-en"],
        ["easyMaps_kiosk", "https://3d-berlin.com/en/products/easymaps-en"],
        ["carFinder", "https://3d-berlin.com/en/products/carfinder-en"],
        ["mapsEngine", "https://3d-berlin.com/en/products/mapsengine-en"],
        ["custom", "https://3d-berlin.com"],
        // ["endOfLife", "https://3d-berlin.com"]
    ]);

    private readonly _oMapProductsOptionsWebsiteLinks: Map<string, string> = new Map([
        ["Add-On: 3D-Wayfinding (Guide3D)", "https://3d-berlin.com/en/products/easyguide-en/easyguide-kiosk-retail-en/#section-5931dd1-2"],
        ["Add-On: Customized Screensaver", "https://3d-berlin.com/en/products/easyguide-en/easyguide-kiosk-retail-en/#section-5931dd1-3"],
        ["Add-On: Banner Ad", "https://3d-berlin.com/en/products/easyguide-en/easyguide-kiosk-retail-en/#section-5931dd1-4"],
        ["Add-On: Offline-Mode", "https://3d-berlin.com/en/products/easyguide-en/easyguide-mobile-en/#section-1a93471b-2"],
        ["Add-On: 'Google Play Store'-App", "https://3d-berlin.com/en/products/easyguide-en/easyguide-mobile-en/#section-1a93471b-3"],
        ["Tool: QR-Code-Generator", "https://3d-berlin.com/en/products/easyguide-en/easyguide-mobile-en/#section-1a93471b-4"],
        ["Add-On: Additional Language", "https://3d-berlin.com/en/products/easyguide-en/#extraLanguage-en"],
        ["Add-On: Data connection", "https://3d-berlin.com/en/products/easyguide-en/#dataconnection-en"],
        ["Add-On: Usage-Statistics", "https://3d-berlin.com/en/products/easyguide-en/#stats-en"],
        ["Add-On: 3D-Environment-Map", "https://3d-berlin.com/en/products/easyguide-en/#3d-map-en"],
        ["Add-On: Level-of-Detail (LOD)", "https://3d-berlin.com/en/products/easyguide-en/#zoom-en"],
        ["Add-On: Sign design according to customer requirements", "https://3d-berlin.com/en/products/easymaps-en/#section-a023eb7-1"],
        ["Deployment: Webhosting on customer side", "https://3d-berlin.com/en/products/easyguide-en"],
        ["Deployment: On-Premises ('on site'-Installation)", "https://3d-berlin.com/en/products/easyguide-en"],
    ]);

    // intern
    private readonly _oSetInternProjectsLanguages: Set<string> = new Set();
    private readonly _oSetInternProjectsCities: Set<string> = new Set();
    private readonly _oSetInternProjectsCountries: Set<string> = new Set();
    private readonly _oSetInternProjectsIndustries: Set<string> = new Set();
    private readonly _oSetInternProjectsProducts: Set<string> = new Set();
    private readonly _oSetInternProjectsProductsOptions: Set<string> = new Set();
    private readonly _oSetInternProjectsKeywords: Set<string> = new Set();

    // extern
    private readonly _oSetExternProjectsLanguages: Set<string> = new Set();
    private readonly _oSetExternProjectsCities: Set<string> = new Set();
    private readonly _oSetExternProjectsCountries: Set<string> = new Set();
    private readonly _oSetExternProjectsIndustries: Set<string> = new Set();
    private readonly _oSetExternProjectsProducts: Set<string> = new Set();
    private readonly _oSetExternProjectsProductsOptions: Set<string> = new Set();
    private readonly _oSetExternProjectsKeywords: Set<string> = new Set();


    // ================================================================== //
    // ====                      Public methods                      ==== //
    // ================================================================== //

    constructor(public oProjectsToMenuConverterOptions: IProjectsToMenuConverterOptions) {

        this._bDebug = !!oProjectsToMenuConverterOptions.debug;
        this._bWarn = this._getUrlParameter("debug")

        this._oGuide3DMenuService.addLanguage("de");
        this._oGuide3DMenuService.setIconsBaseUrl("");
        /*
        this._oGuide3DMenuService.addSet({ "id": "set-easyGuide", "name": "easyGuide", "icon": "easyGuide.png", "path": [Guide3DMenuService.MENU_TYPE_START] });
        this._oGuide3DMenuService.addSet({ "id": "set-easyMaps", "name": "easyMaps", "icon": "easyMaps.png", "path": [Guide3DMenuService.MENU_TYPE_START] });
        this._oGuide3DMenuService.addSet({ "id": "set-mapsEngine", "name": "mapsEngine", "icon": "mapsEngine.png", "path": [Guide3DMenuService.MENU_TYPE_START] });
        this._oGuide3DMenuService.addSet({ "id": "set-carFinder", "name": "carFinder", "icon": "carFinder.png", "path": [Guide3DMenuService.MENU_TYPE_START] });
        this._oGuide3DMenuService.addSet({ "id": "set-custom", "name": "Custom development apps", "icon": "custom.png", "path": [Guide3DMenuService.MENU_TYPE_START] });
        this._oGuide3DMenuService.addSet({ "id": "set-endOfLife", "name": "End-of-life apps", "icon": "endOfLife.png", "path": [Guide3DMenuService.MENU_TYPE_START] });
        */
        // console.log(this._oGuide3DMenuService.toString() + " loaded");

        const sProjectsDataFeedUrl: string = (oProjectsToMenuConverterOptions.beta) ? this._sProjectsDataFeedUrlBeta : this._sProjectsDataFeedUrlLive;
        Guide3DMenuService.fetchJson(sProjectsDataFeedUrl).then((oProjectsList: IServiceCenterProject[]) => {
            oProjectsList.reverse().forEach((oProject: IServiceCenterProject, nIndex: number) => {
                if (String(oProject.id).startsWith("100")) {
                    // if (oProject.service) {
                    this._addProject(oProject, nIndex);
                    // }
                }
            });

            if (typeof (oProjectsToMenuConverterOptions.onload) === "function") {
                oProjectsToMenuConverterOptions.onload({
                    "intern": {
                        "count": this._oGuide3DMenuService.getStartItems().length,
                        "languages": Array.from(this._oSetInternProjectsLanguages).sort(),
                        "cities": Array.from(this._oSetInternProjectsCities).sort(),
                        "countries": Array.from(this._oSetInternProjectsCountries).sort(),
                        "industries": Array.from(this._oSetInternProjectsIndustries).sort(),
                        "products": Array.from(this._oSetInternProjectsProducts).sort(),
                        "productsOptions": Array.from(this._oSetInternProjectsProductsOptions).sort(),
                        "keywords": Array.from(this._oSetInternProjectsKeywords).sort(),
                    },
                    "extern": {
                        "count": this._oGuide3DMenuService.getEndItems().length,
                        "languages": Array.from(this._oSetExternProjectsLanguages).sort(),
                        "cities": Array.from(this._oSetExternProjectsCities).sort(),
                        "countries": Array.from(this._oSetExternProjectsCountries).sort(),
                        "industries": Array.from(this._oSetExternProjectsIndustries).sort(),
                        "products": Array.from(this._oSetExternProjectsProducts).sort(),
                        "productsOptions": Array.from(this._oSetExternProjectsProductsOptions).sort(),
                        "keywords": Array.from(this._oSetExternProjectsKeywords).sort(),
                    },
                    "menu": this._oGuide3DMenuService
                })
            } else {
                this.printWarning("constructor", "The \"oProjectsToMenuConverterOptions.onload\" option is not a function.")
            }

        });

    }

    public setLanguage(str: string) {
        this._oGuide3DMenuService.setLanguage(str);
    }

    public toString(): string {

        return ProjectsToMenuConverter.toString();

    }

    public exportMenu(): IMenuPreloadType {
        return this._oGuide3DMenuService.export();
    }

    public printWarning(sFunctionName: string, sWarningMessage: string): void {

        if(this._bWarn === "true"){
            console.warn(this.toString() + " @ " + sFunctionName + "(): " + sWarningMessage);
        }
        return;

    }

    public saveMenu(sFilename: string): void {

        if (!sFilename) {
            sFilename = "Guide3D-MenuPreload-999999.json";
        }
        // https://robkendal.co.uk/blog/2020-04-17-saving-text-to-client-side-file-using-vanilla-js
        const oBlob: Blob = new Blob([JSON.stringify(this.exportMenu())], { type: "text/plain;charset=utf-8" });
        const oElementA: HTMLAnchorElement = window.document.createElement("a");
        oElementA.href = URL.createObjectURL(oBlob);
        oElementA.download = sFilename;
        oElementA.click();
        URL.revokeObjectURL(oElementA.href);

        return;

    }

    // ================================================================== //
    // ====                    Protected methods                     ==== //
    // ================================================================== //

    // ================================================================== //
    // ====                     Private methods                      ==== //
    // ================================================================== //

    private _addProject(oProject: IServiceCenterProject, nIndex: number): void {

        const nProject: number = oProject.id;
        const bPublic: boolean = (oProject.demo && oProject.public);
        const bService: boolean = oProject.service;
        const sProjectName: string = oProject.name.trim();
        const sCity: string = this._getCity(oProject.city, nProject);
        const oCountry: [string, string] = this._getCountry(oProject.country, nProject);
        const oLanguages: IProjectLanguages = this._getLanguages(oProject.languages, nProject);
        const oIndustry: [string, string] = this._getIndustry(oProject.market.trim(), nProject);
        const sReferenceUrl: string = this._getReferenceUrl(oProject.referenceUrl || "", nProject);
        const bDataConnection: boolean = oProject.dataConnection;
        const oProductsOptions: IProjectProductsOption[] = this._getProductsOptions(oProject.keywords, nProject);
        const oProducts: IProjectProduct[] = this._getProducts(sProjectName, oProject.productsData, nProject, bPublic, bService, oProductsOptions, oProject.kiosk.platform.trim(), (oProject.kiosk.technology.trim().toLowerCase() === "flash"));
        const sIcon = "https://services.guide3d.com/images/" + nProject + "/80x80/Logo.png";

        /* console.log("nProject: " + nProject);
        console.log("this._oGuide3DMenuService.getLanguage() " + this._oGuide3DMenuService.getLanguage()); */

        const oData: IConverterProject = {
            "project": nProject,
            "public": bPublic,
            "service": bService,
            "name": sProjectName,
            "city": sCity,
            "country": (this._oGuide3DMenuService.getLanguage() === "de") ? oCountry[1] : oCountry[0],
            "languages": oLanguages,
            "industry": (this._oGuide3DMenuService.getLanguage() === "de") ? oIndustry[1] : oIndustry[0],
            "reference": sReferenceUrl,
            "dataConnection": bDataConnection,
            "products": oProducts,
            "productsOptions": oProductsOptions,
            "validated": (oProject.keywords.indexOf("validated") !== -1) ? true : false,
        };

        // project sets lists
        this._oSetInternProjectsCities.add(oData.city);
        this._oSetInternProjectsCountries.add(oData.country)
        Object.keys(oData.languages).forEach((sLanguageCode) => this._oSetInternProjectsLanguages.add(oData.languages[sLanguageCode]));
        this._oSetInternProjectsIndustries.add(oData.industry)
        oData.products.forEach((oProduct) => this._oSetInternProjectsProducts.add(oProduct.name));
        this._retrieveProductsOptionsNames(oData.productsOptions).forEach((sProductOption) => this._oSetInternProjectsProductsOptions.add(sProductOption));
        if (bPublic) {
            this._oSetExternProjectsCities.add(oData.city);
            this._oSetExternProjectsCountries.add(oData.country)
            Object.keys(oData.languages).forEach((sLanguageCode) => this._oSetExternProjectsLanguages.add(sLanguageCode));
            this._oSetExternProjectsIndustries.add(oData.industry)
            oData.products.forEach((oProduct) => this._oSetExternProjectsProducts.add(oProduct.name));
            this._retrieveProductsOptionsNames(oData.productsOptions).forEach((sProductOption) => this._oSetExternProjectsProductsOptions.add(sProductOption));
        }

        const sKeywords: string = this._getMenuItemKeywords(nProject, Object.values(oLanguages), oProducts, oProductsOptions, this._getCustomKeywords(oProject.keywords, bPublic, nProject), oProject);

        // this._oGuide3DMenuService.addPointItem({ "id": "start-item-" + String(nProject), "display": bService, "default": true, "name": String(nProject) + ": " + sProjectName, "description": oIndustry, "location": [sCity + "," + oCountry[0], sCity + "," + oCountry[1]], "keywords": this._getMenuItemKeywords(nProject, oProducts, oProductsOptions), "url": sReferenceUrl, "icon": sIcon, "sets": this._getMenuItemSets(nProject, oProducts), point: "L99P9999", data: JSON.stringify(oData) });
        this._oGuide3DMenuService.addPointItem({ "id": "start-item-" + String(nProject), "display": bPublic, "default": bService, "name": String(nProject - 100000) + ": " + sProjectName, "description": oIndustry, "location": [sCity + "," + oCountry[0], sCity + "," + oCountry[1]], "keywords": sKeywords, "url": sReferenceUrl, "icon": sIcon, "sets": [Guide3DMenuService.MENU_TYPE_START], point: "L99P9999", data: JSON.stringify(oData) });
        if (bPublic) {
            this._oGuide3DMenuService.addPointItem({ "id": "end-item-" + String(nProject), "display": bPublic, "default": true, "name": String(nProject - 100000) + ": " + sProjectName, "description": oIndustry, "location": [sCity + "," + oCountry[0], sCity + "," + oCountry[1]], "keywords": sKeywords, "url": sReferenceUrl, "icon": sIcon, "sets": [Guide3DMenuService.MENU_TYPE_END], point: "L99P9999", data: JSON.stringify(oData) });
        }

        return;

    }

    private _getCity(sCity: string, nProject: number): string {

        if (sCity) {
            sCity = sCity.trim();
        } else {
            sCity = "unknown-city";
            this.printWarning("_getCity", "The city \"" + sCity + "\" of project " + nProject + " is not supported.")
        }

        return sCity;

    }

    private _getCountry(sCountryCode: string, nProject: number): [string, string] {

        let oCountry: [string, string] = ["Other country", "Anderes Land"];

        switch (sCountryCode) {
            case "AL": oCountry = ["Albania", "Albanien"]; break;
            case "DZ": oCountry = ["Algeria", "Algerien"]; break;
            case "AR": oCountry = ["Argentina", "Argentinien"]; break;
            case "AU": oCountry = ["Australia", "Australien"]; break;
            case "AT": oCountry = ["Austria", "Österreich"]; break;
            case "BH": oCountry = ["Bahrain", "Bahrein"]; break;
            case "BY": oCountry = ["Belarus", "Weißrussland"]; break;
            case "BE": oCountry = ["Belgium", "Belgien"]; break;
            case "BO": oCountry = ["Bolivia", "Bolivien"]; break;
            case "BA": oCountry = ["Bosnia and Herzegovina", "Bosnien und Herzegowina"]; break;
            case "BR": oCountry = ["Brazil", "Brasilien"]; break;
            case "BG": oCountry = ["Bulgaria", "Bulgarien"]; break;
            case "CA": oCountry = ["Canada", "Kanada"]; break;
            case "CL": oCountry = ["Chile", "Chile"]; break;
            case "CN": oCountry = ["China", "China"]; break;
            case "CO": oCountry = ["Colombia", "Kolumbien"]; break;
            case "CR": oCountry = ["Costa Rica", "Costa Rica"]; break;
            case "HR": oCountry = ["Croatia", "Kroatien"]; break;
            case "CU": oCountry = ["Cuba", "Kuba"]; break;
            case "CY": oCountry = ["Cyprus", "Zypern"]; break;
            case "CZ": oCountry = ["Czech Republic", "Tschechien"]; break;
            case "DK": oCountry = ["Denmark", "Dänemark"]; break;
            case "DO": oCountry = ["Dominican Republic", "Dominikanische Republik"]; break;
            case "EC": oCountry = ["Ecuador", "Ecuador"]; break;
            case "EG": oCountry = ["Egypt", "Ägypten"]; break;
            case "SV": oCountry = ["El Salvador", "El Salvador"]; break;
            case "EE": oCountry = ["Estonia", "Estland"]; break;
            case "FI": oCountry = ["Finland", "Finnland"]; break;
            case "FR": oCountry = ["France", "Frankreich"]; break;
            case "DE": oCountry = ["Germany", "Deutschland"]; break;
            case "GR": oCountry = ["Greece", "Griechenland"]; break;
            case "GT": oCountry = ["Guatemala", "Guatemala"]; break;
            case "HN": oCountry = ["Honduras", "Honduras"]; break;
            case "HK": oCountry = ["Hong Kong", "Hongkong"]; break;
            case "HU": oCountry = ["Hungary", "Ungarn"]; break;
            case "IS": oCountry = ["Iceland", "Island"]; break;
            case "IN": oCountry = ["India", "Indien"]; break;
            case "ID": oCountry = ["Indonesia", "Indonesien"]; break;
            case "IQ": oCountry = ["Iraq", "Irak"]; break;
            case "IE": oCountry = ["Ireland", "Irland"]; break;
            case "IL": oCountry = ["Israel", "Israel"]; break;
            case "IT": oCountry = ["Italy", "Italien"]; break;
            case "JP": oCountry = ["Japan", "Japan"]; break;
            case "JO": oCountry = ["Jordan", "Jordanien"]; break;
            case "KW": oCountry = ["Kuwait", "Kuwait"]; break;
            case "LV": oCountry = ["Latvia", "Lettland"]; break;
            case "LB": oCountry = ["Lebanon", "Libanon"]; break;
            case "LY": oCountry = ["Libya", "Libyen"]; break;
            case "LT": oCountry = ["Lithuania", "Litauen"]; break;
            case "LU": oCountry = ["Luxembourg", "Luxemburg"]; break;
            case "MK": oCountry = ["Macedonia", "Mazedonien"]; break;
            case "MY": oCountry = ["Malaysia", "Malaysia"]; break;
            case "MT": oCountry = ["Malta", "Malta"]; break;
            case "MX": oCountry = ["Mexico", "Mexiko"]; break;
            case "ME": oCountry = ["Montenegro", "Montenegro"]; break;
            case "MA": oCountry = ["Morocco", "Marokko"]; break;
            case "NL": oCountry = ["Netherlands", "Niederlande"]; break;
            case "NZ": oCountry = ["New Zealand", "Neuseeland"]; break;
            case "NI": oCountry = ["Nicaragua", "Nicaragua"]; break;
            case "NO": oCountry = ["Norway", "Norwegen"]; break;
            case "OM": oCountry = ["Oman", "Oman"]; break;
            case "PA": oCountry = ["Panama", "Panama"]; break;
            case "PY": oCountry = ["Paraguay", "Paraguay"]; break;
            case "PE": oCountry = ["Peru", "Peru"]; break;
            case "PH": oCountry = ["Philippines", "Philippinen"]; break;
            case "PL": oCountry = ["Poland", "Polen"]; break;
            case "PT": oCountry = ["Portugal", "Portugal"]; break;
            case "PR": oCountry = ["Puerto Rico", "Puerto Rico"]; break;
            case "QA": oCountry = ["Qatar", "Katar"]; break;
            case "RO": oCountry = ["Romania", "Rumänien"]; break;
            case "RU": oCountry = ["Russia", "Russland"]; break;
            case "SA": oCountry = ["Saudi Arabia", "Saudi-Arabien"]; break;
            case "RS": oCountry = ["Serbia", "Serbien"]; break;
            case "CS": oCountry = ["Serbia and Montenegro", "Serbien und Montenegro"]; break;
            case "SG": oCountry = ["Singapore", "Singapur"]; break;
            case "SK": oCountry = ["Slovakia", "Slowakei"]; break;
            case "SI": oCountry = ["Slovenia", "Slowenien"]; break;
            case "ZA": oCountry = ["South Africa", "Südafrika"]; break;
            case "KR": oCountry = ["South Korea", "Südkorea"]; break;
            case "ES": oCountry = ["Spain", "Spanien"]; break;
            case "SD": oCountry = ["Sudan", "Sudan"]; break;
            case "SE": oCountry = ["Sweden", "Schweden"]; break;
            case "CH": oCountry = ["Switzerland", "Schweiz"]; break;
            case "SY": oCountry = ["Syria", "Syrien"]; break;
            case "TW": oCountry = ["Taiwan", "Taiwan"]; break;
            case "TH": oCountry = ["Thailand", "Thailand"]; break;
            case "TN": oCountry = ["Tunisia", "Tunesien"]; break;
            case "TR": oCountry = ["Turkey", "Türkei"]; break;
            case "UA": oCountry = ["Ukraine", "Ukraine"]; break;
            case "AE": oCountry = ["United Arab Emirates", "Vereinigte Arabische Emirate"]; break;
            case "GB": oCountry = ["United Kingdom", "Großbritannien"]; break;
            case "US": oCountry = ["United States", "Vereinigte Staaten"]; break;
            case "UY": oCountry = ["Uruguay", "Uruguay"]; break;
            case "VE": oCountry = ["Venezuela", "Venezuela"]; break;
            case "VN": oCountry = ["Vietnam", "Vietnam"]; break;
            case "YE": oCountry = ["Yemen", "Jemen"]; break;
            default:
                this.printWarning("_getCountry", "The country code \"" + sCountryCode + "\" of project " + nProject + " is not supported.")
                break;
        }

        return oCountry;

    }

    private _getLanguages(oLanguageCodes: string[], nProject: number): IProjectLanguages {

        const oLanguages: IProjectLanguages = {};

        oLanguageCodes.forEach((sLanguageCode: string) => {
            sLanguageCode = sLanguageCode.trim();
            sLanguageCode = (sLanguageCode === "zhans") ? "zh-hans" : sLanguageCode;
            sLanguageCode = (sLanguageCode === "zhant") ? "zh-hant" : sLanguageCode;

            const sLanguageName: string = Guide3DLanguageService.getLanguageDescription(sLanguageCode);
            if (sLanguageName) {
                oLanguages[sLanguageCode] = sLanguageName;
            } else {
                this.printWarning("_getLanguages", "The language code \"" + sLanguageCode + "\" of project " + nProject + " is not supported.")
            }

        });

        return oLanguages;

    }

    private _getIndustry(sMarket: string, nProject: number) {

        let oIndustry: [string, string] = ["Other", "Weitere"];

        switch (sMarket) {
            case "Hospitals & Healthcare":
                oIndustry = [sMarket, "Krankenhäuser & Gesundheitswesen"];
                break;
            case "Airports & Transportation":
                oIndustry = [sMarket, "Flughäfen & Transport"];
                break;
            case "Universities & Education":
                oIndustry = [sMarket, "Universitäten & Bildung"];
                break;
            case "Corporate Offices & Organizations":
                oIndustry = [sMarket, "Unternehmenszentralen & Organisation"];
                break;
            case "Shopping Malls & Retail":
                oIndustry = [sMarket, "Einkaufszentren & Einzelhandel"];
                break;
            case "Government Agencies & Administration":
                oIndustry = [sMarket, "Behörden & Verwaltung"];
                break;
            case "Convention Centers & Events":
                oIndustry = [sMarket, "Kongresszentren & Veranstaltungen"];
                break;
            case "Theme parks & Entertainment":
                oIndustry = [sMarket, "Vergnügunsparks & Unterhaltung"];
                break;
            case "Stadiums & Sports":
                oIndustry = [sMarket, "Stadien & Sportstätten"];
                break;
            case "Hotels & Vacation":
                oIndustry = [sMarket, "Hotels & Erholung"];
                break;
            case "Museums & Culture":
                oIndustry = [sMarket, "Museen & Kultur"];
                break;
            case "Cruise ships & Leisure":
                oIndustry = [sMarket, "Kreuzfahrtschiffe & Urlaub"];
                break;
            case "Parking garage & Logistics":
                oIndustry = [sMarket, "Parkhäuser & Logistik"];
                break;
            case "Other":
                oIndustry = [sMarket, "Weitere"];
                break;
            default:
                this.printWarning("_getIndustry", "The market / industry \"" + sMarket + "\" of project " + nProject + " is unknown.")
                break;
        }

        return oIndustry;

    }

    private _getReferenceUrl(sReferenceUrl: string, nProject: number) {

        if (sReferenceUrl) {
            if (sReferenceUrl.startsWith("https://") === false) {
                this.printWarning("_getReferenceUrl", "Reference url \"" + sReferenceUrl + "\" of project " + nProject + " does not start with 'https://'.")
            }
            return sReferenceUrl.trim();
        } else {
            ProjectsToMenuConverter.printDebug("_getReferenceUrl", "No reference url given for project " + nProject + ".", this._bDebug);
        }

        return "https://3d-berlin.com/en/references-en";

    }

    private _getProductsOptions(oKeywords: string[], nProject: number): IProjectProductsOption[] {

        const oProductsOptions: IProjectProductsOption[] = [];

        oKeywords.sort().forEach((sKeyword: string) => {
            sKeyword = sKeyword.trim();
            if (this._oMapProductsOptionsWebsiteLinks.has(sKeyword)) {
                oProductsOptions.push({
                    "name": sKeyword,
                    "type": sKeyword.split(":")[0].toLowerCase() as "add-on" | "tool" | "deployment",
                    "links": {
                        "description": this._oMapProductsOptionsWebsiteLinks.get(sKeyword)
                    }
                });
            } else {
                if (sKeyword.startsWith("Add-On:")) {
                    this.printWarning("_getProductsOptions", "The 'Add-On' \"" + sKeyword + "\" for project " + nProject + " is not supported.");
                }
                if (sKeyword.startsWith("Tool:")) {
                    this.printWarning("_getProductsOptions", "The 'Tool' \"" + sKeyword + "\" for project " + nProject + " is not supported.");
                }
                if (sKeyword.startsWith("Deployment:")) {
                    this.printWarning("_getProductsOptions", "The 'Deployment' \"" + sKeyword + "\" for project " + nProject + " is not supported.");
                }
                ProjectsToMenuConverter.printDebug("_getProductsOptions", "The keyword \"" + sKeyword + "\" for project " + nProject + " is not supported.", this._bDebug);
            }
        });

        return oProductsOptions;
    }

    private _retrieveProductsOptionsNames(oProductsOptions: IProjectProductsOption[]): string[] {
        const oProductsOptionsNames: string[] = [];

        oProductsOptions.forEach((oProductsOption: IProjectProductsOption) => {
            oProductsOptionsNames.push(oProductsOption.name);
        });

        return oProductsOptionsNames;

    }

    private _getProducts(sProjectName: string, oProductsData: Array<{ "name": string, "url": string }>, nProject: number, bPublic: boolean, bService: boolean, oProductsOptions: IProjectProductsOption[], sKioskPlatform: string, bIsKioskFlashApplication: boolean): IProjectProduct[] {

        const oProducts: IProjectProduct[] = [];

        oProductsData.forEach((oProductData: { "name": string, "url": string }) => {

            switch (oProductData.name) {
                case "kiosk easyGuide":
                case "kiosk hostingPremises":
                case "kiosk guide3D": {
                    let nMatches: number = 0;
                    const bOnPremises = (oProductData.name === "kiosk hostingPremises") ? true : false;

                    if (bIsKioskFlashApplication === false) {
                        if (/easyMaps/i.test(sProjectName + oProductData.url)) {
                            // easyMaps kiosk
                            oProducts.push(this._getEasyMapsKioskProduct(nProject, oProductData.url, sKioskPlatform));
                        } else {
                            // easyGuide kiosk
                            oProducts.push(this._getEasyGuideKioskProduct(nProject, oProductData.url, bService, bOnPremises, sKioskPlatform));
                        }
                        nMatches++;
                    } else {  // End-of-life (kiosk)
                        oProducts.push(this._getEolKioskProduct(nProject, oProductData.url, bService, bOnPremises, sKioskPlatform));
                        nMatches++;
                    }

                    if (nMatches !== 1) {
                        ProjectsToMenuConverter.printError("_getProducts", "Found no or too many kiosk products for project " + nProject + ".")
                    }

                    if (oProductData.name === "kiosk guide3D") {
                        if (this._retrieveProductsOptionsNames(oProductsOptions).includes("Add-On: 3D-Wayfinding (Guide3D)") === false) {
                            this.printWarning("_getProducts", "Product option 'Add-On: 3D-Wayfinding (Guide3D)' for project " + nProject + " is missing!")
                        }
                    }
                    if (bOnPremises) {
                        if (this._retrieveProductsOptionsNames(oProductsOptions).includes("Deployment: On-Premises") === false) {
                            this.printWarning("_getProducts", "Product option 'Deployment: On-Premises' (kiosk) for project " + nProject + " is missing.")
                        }
                    }
                }
                    break;
                case "mobile easyGuide":
                    if (/Car-?Finder/i.test(sProjectName)) {
                        oProducts.push(this._getCarFinderProduct(nProject, oProductData.url, bService));
                    } else {
                        if (/easyMaps/i.test(sProjectName + oProductData.url)) {
                            oProducts.push(this._getEasyMapsMobileProduct(nProject, oProductData.url));
                        } else {
                            let bHasQrCodeGeneratorTool: boolean = false;
                            oProductsOptions.forEach((oProductsOption: IProjectProductsOption) => {
                                if (oProductsOption.name === "Tool: QR-Code-Generator") {
                                    bHasQrCodeGeneratorTool = true;
                                }
                            });
                            oProducts.push(this._getEasyGuideMobileProduct(nProject, oProductData.url, bService, false, bHasQrCodeGeneratorTool));
                        }
                    }
                    break;
                case "mobile hostingPremises": {
                    let bHasQrCodeGeneratorTool: boolean = false;
                    oProductsOptions.forEach((oProductsOption: IProjectProductsOption) => {
                        if (oProductsOption.name === "Tool: QR-Code-Generator") {
                            bHasQrCodeGeneratorTool = true;
                        }
                    });
                    oProducts.push(this._getEasyGuideMobileProduct(nProject, oProductData.url, bService, true, bHasQrCodeGeneratorTool));
                    if (this._retrieveProductsOptionsNames(oProductsOptions).includes("Deployment: On-Premises") === false) {
                        this.printWarning("_getProducts", "Product option 'Deployment: On-Premises' (mobile) for project " + nProject + " is missing.")
                    }
                }
                    break;
                case "mobile guide3D":
                    oProducts.push(this._getEolMobileProduct(nProject, oProductData.url, bService));
                    break;
                case "web easyGuide":
                    oProducts.push(this._getEasyGuideWebProduct(nProject, oProductData.url, bService, false));
                    break;
                case "web hostingPremises":
                    oProducts.push(this._getEasyGuideWebProduct(nProject, oProductData.url, bService, true));
                    break;
                case "web guide3D":
                    oProducts.push(this._getEolWebProduct(nProject, oProductData.url, bService));
                    break;
                case "print easyGuide":
                    oProducts.push(this._getEasyGuidePrintProduct(nProject, oProductData.url, false));
                    break;
                case "print hostingPremises":
                    oProducts.push(this._getEasyGuidePrintProduct(nProject, oProductData.url, true));
                    break;
                case "maps-engine easyGuide":
                case "engine easyGuide":
                    oProducts.push(this._getMapsEngineProduct(nProject, oProductData.url, false));
                    break;
                case "maps-engine hostingPremises":
                case "engine hostingPremises":
                    oProducts.push(this._getMapsEngineProduct(nProject, oProductData.url, true));
                    break;

            }
        });

        if (oProducts.length === 0) {
            this.printWarning("_getProducts", "No supported products found for project " + nProject + ".")
            if (sProjectName.indexOf("Car-Finder") >= 0) {
                oProducts.push(this._getCarFinderProduct(nProject, oProductsData[0].url, bService));
            }
        }

        return oProducts;

    }

    /* easyGuide */
    private _getEasyGuideKioskProduct(nProject: number, sLinkUrl: string, bService: boolean, bOnPremises: boolean, sKioskPlatform: string): IProjectProduct {

        // "Smart City - 3D-Simulation" - Delta Electronics
        if (nProject === 100116) {
            return {
                "name": "Kiosk (custom development)",
                "type": "custom",
                "onPremises": false,
                "links": {
                    "url": sLinkUrl
                }
            };
        }

        const oProduct: IProjectProduct = {
            "name": "easyGuide kiosk",
            "type": "easyGuide_kiosk",
            "onPremises": bOnPremises,
            "links": {
                "url": sLinkUrl,
                "app": "https://app.guide3d.com/" + nProject + "/kiosk",
                "description": this._oMapProductsWebsiteLinks.get("easyGuide_kiosk"),
                "kioskList": "https://app.guide3d.com/" + nProject + "/kiosk/list"
            }
        }

        if (sKioskPlatform) {
            oProduct.kioskPlatform = sKioskPlatform;
        }

        if ((bService === true) && (bOnPremises === false)) {
            oProduct.links!.status = "http://services.guide3d.com/logger/mobile/?token=NDQ2MjQy";
        }

        return oProduct;

    }

    private _getEasyGuideMobileProduct(nProject: number, sLinkUrl: string, bService: boolean, bOnPremises: boolean, bHasQrCodeGeneratorTool: boolean): IProjectProduct {

        const oProduct: IProjectProduct = {
            "name": "easyGuide mobile",
            "type": "easyGuide_mobile",
            "onPremises": bOnPremises,
            "links": {
                "url": sLinkUrl,
                "app": "https://app.guide3d.com/" + nProject + "/mobile",
                "description": this._oMapProductsWebsiteLinks.get("easyGuide_mobile"),
                "mobileSimulator": "https://www.easy-guide.mobi/simulator/" + nProject,
            }
        }

        if (bHasQrCodeGeneratorTool) {
            oProduct.links!.qrCodeGenerator = "https://app.guide3d.com/" + nProject + "/qr-code-generator";
        }

        if ((bService === true) && (bOnPremises === false)) {
            oProduct.links!.status = "https://services.guide3d.com/status/js/?" + nProject;
        }

        return oProduct;

    }

    private _getEasyGuideWebProduct(nProject: number, sLinkUrl: string, bService: boolean, bOnPremises: boolean): IProjectProduct {

        // "MyBox"
        if (nProject === 100027) {
            return {
                "name": "Web (custom development)",
                "type": "custom",
                "onPremises": false,
                "links": {
                    "url": sLinkUrl
                }
            };
        }

        const oProduct: IProjectProduct = {
            "name": "easyGuide web",
            "type": "easyGuide_web",
            "onPremises": bOnPremises,
            "links": {
                "url": sLinkUrl,
                "app": "https://app.guide3d.com/" + nProject + "/web?menu=start-end&mode=M0000&share=true&mobile=true&print=true&language=en",
                "description": this._oMapProductsWebsiteLinks.get("easyGuide_web"),
                "webConfigurator": "https://app.guide3d.com/" + nProject + "/web-configurator"
            }
        }

        if ((bService === true) && (bOnPremises === false)) {
            oProduct.links!.status = "https://services.guide3d.com/status/js/?" + nProject;
        }

        return oProduct;

    }

    private _getEasyGuidePrintProduct(nProject: number, sLinkUrl: string, bOnPremises: boolean): IProjectProduct {

        // 'Freie Universität, Executive Office' or 'National library of Uzbekistan'
        if ((nProject === 100001) || (nProject === 100006)) {
            return {
                "name": "'End-of-life' print-application ('Guide3D print')",
                "type": "endOfLife",
                "onPremises": false,
                "links": {
                    "url": sLinkUrl
                }
            };
        }

        const oProduct: IProjectProduct = {
            "name": "easyGuide print",
            "type": "easyGuide_print",
            "onPremises": bOnPremises,
            "links": {
                "url": sLinkUrl,
                "app": "https://app.guide3d.com/" + nProject + "/print",
                "description": this._oMapProductsWebsiteLinks.get("easyGuide_print"),
            }
        }

        return oProduct;

    }

    /* easyMaps */
    private _getEasyMapsMobileProduct(nProject: number, sLinkUrl: string): IProjectProduct {

        const oProduct: IProjectProduct = {
            "name": "easyMaps mobile",
            "type": "easyMaps_mobile",
            "onPremises": false,
            "links": {
                "url": sLinkUrl,
                "app": "https://app.guide3d.com/" + nProject + "/mobile",
                "description": this._oMapProductsWebsiteLinks.get("easyMaps_mobile"),
                // "mobileSimulator": "https://www.easy-guide.mobi/simulator/" + nProject
            }
        }

        return oProduct;

    }

    private _getUrlParameter(name: string) {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(document.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    private _getEasyMapsKioskProduct(nProject: number, sLinkUrl: string, sKioskPlatform: string): IProjectProduct {

        const oProduct: IProjectProduct = {
            "name": "easyMaps kiosk",
            "type": "easyMaps_kiosk",
            "onPremises": false,
            "links": {
                "url": sLinkUrl,
                "app": "https://app.guide3d.com/" + nProject + "/kiosk",
                "description": this._oMapProductsWebsiteLinks.get("easyMaps_kiosk"),
                "kioskList": "https://app.guide3d.com/" + nProject + "/kiosk/list"
            },
            "kioskPlatform": (sKioskPlatform) ? sKioskPlatform : "unknown-kiosk-platform"
        }

        return oProduct;

    }

    /* carFinder */
    private _getCarFinderProduct(nProject: number, sLinkUrl: string, bService: boolean): IProjectProduct {

        const oProduct: IProjectProduct = {
            "name": "carFinder",
            "type": "carFinder",
            "onPremises": false,
            "links": {
                "url": sLinkUrl,
                "app": "https://www.easy-guide.mobi/" + nProject,
                "description": this._oMapProductsWebsiteLinks.get("carFinder"),
                "mobileSimulator": "https://www.easy-guide.mobi/simulator/" + nProject
            }
        }

        if (bService === true) {
            oProduct.links!.status = "https://services.guide3d.com/status/js/?" + nProject;
        }

        return oProduct;

    }

    /* mapsEngine */
    private _getMapsEngineProduct(nProject: number, sLinkUrl: string, bOnPremises: boolean): IProjectProduct {

        const oProduct: IProjectProduct = {
            "name": "mapsEngine",
            "type": "mapsEngine",
            "onPremises": bOnPremises,
            "links": {
                "url": sLinkUrl,
                "app": "https://engine.easy-guide.com/example.html?project=" + nProject + "&debug=false&end=",
                "description": this._oMapProductsWebsiteLinks.get("mapsEngine"),
            }
        }

        return oProduct;

    }

    /* end-of-life */
    private _getEolKioskProduct(nProject: number, sLinkUrl: string, bService: boolean, bOnPremises: boolean, sKioskPlatform: string): IProjectProduct {

        const oProduct: IProjectProduct = {
            "name": "'End-of-life' kiosk-application (custom development or 'Guide3D kiosk')",
            "type": "endOfLife",
            "onPremises": bOnPremises,
            "links": {
                "url": sLinkUrl,
            },
            "kioskPlatform": (sKioskPlatform) ? sKioskPlatform : "unknown-kiosk-platform"
        }

        if (bService) {
            this.printWarning("_getEolKioskProduct", "'End-of-life' kiosk-application project " + nProject + " can not be in service.")
        }

        return oProduct;

    }

    private _getEolMobileProduct(nProject: number, sLinkUrl: string, bService: boolean): IProjectProduct {

        const oProduct: IProjectProduct = {
            "name": "'End-of-life' mobile-application ('Guide3D mobile')",
            "type": "endOfLife",
            "onPremises": false,
            "links": {
                "url": sLinkUrl,

            }
        }

        if (bService) {
            this.printWarning("_getEolMobileProduct", "'End-of-life' mobile-application project " + nProject + " can not be in service.")
        }

        return oProduct;

    }

    private _getEolWebProduct(nProject: number, sLinkUrl: string, bService: boolean): IProjectProduct {

        const oProduct: IProjectProduct = {
            "name": "'End-of-life' web-application (custom development or 'Guide3D web')",
            "type": "endOfLife",
            "onPremises": false,
            "links": {
                "url": sLinkUrl
            }
        }

        if (bService) {
            this.printWarning("_getEolWebProduct", "'End-of-life' web-application project " + nProject + " can not be in service.")
        }

        return oProduct;

    }

    private _getCustomKeywords(oKeywordsServiceCenter: string[], bPublic: boolean, nProject: number) {

        let oCustomKeywords: string[] = [];

        oKeywordsServiceCenter.sort().forEach((sKeywordServiceCenter: string) => {
            sKeywordServiceCenter = sKeywordServiceCenter.trim();
            if (sKeywordServiceCenter.startsWith("Add-On:") || sKeywordServiceCenter.startsWith("Tool:") || sKeywordServiceCenter.startsWith("Deployment:")) {
                ProjectsToMenuConverter.printDebug("_getCustomKeywords", "The service-center keyword '" + sKeywordServiceCenter + "' for project " + nProject + " is a product option and will not be added to the list of custom keywords.", this._bDebug);
            } else {
                // const oCustomKeywordsWhiteList: string[] = ["ECE", "FU Berlin", "Festo", "Outdoor", "STC"];
                // if (oCustomKeywordsWhiteList.includes(sKeywordServiceCenter)) {
                    if (bPublic) {
                        this._oSetInternProjectsKeywords.add(sKeywordServiceCenter);
                        this._oSetExternProjectsKeywords.add(sKeywordServiceCenter);
                    } else {
                        this._oSetInternProjectsKeywords.add(sKeywordServiceCenter);
                    }
                /* } else {
                    this.printWarning("_getCustomKeywords", "The service-center keyword '" + sKeywordServiceCenter + "' for project " + nProject + " is not on the whitelist and will be ignored.");
                } */
            }
        });

        return oCustomKeywords;

    }

    private _getMenuItemKeywords(nProject: number, oLanguages: string[], oProducts: IProjectProduct[], oProductsOptions: IProjectProductsOption[], oCustomKeywords: string[], oProject: IServiceCenterProject): string {

        let oKeywords: string[] = [String(nProject)];

        oKeywords = oKeywords.concat(oLanguages)

        oProducts.forEach((oProduct: IProjectProduct) => {
            oKeywords.push(oProduct.name);
            if (oProduct.kioskPlatform) {
                oKeywords.push(oProduct.kioskPlatform);
            }
        });

        let oIndustry: string[] = this._getIndustry(oProject.market.trim(), nProject);
        /* oIndustry.forEach((str: string) => {
            oKeywords.push(str);
        }); */
        const industry: string = (this._oGuide3DMenuService.getLanguage() === "de") ? oIndustry[1] : oIndustry[0];
        oKeywords.push(industry);

        /* console.log("oProject.country: " + oProject.country);
        console.log("oProject.city: " + oProject.city); */
        const oCountry: [string, string] = this._getCountry(oProject.country, nProject);
        const sCountry: string = (this._oGuide3DMenuService.getLanguage() === "de") ? oCountry[1] : oCountry[0];
        oKeywords.push(oProject.city);
        oKeywords.push(sCountry);

        this._retrieveProductsOptionsNames(oProductsOptions).forEach((sProductOption: string) => {
            oKeywords.push(sProductOption);
        });

        oKeywords = oKeywords.concat(oCustomKeywords)

        return oKeywords.join(",");

    }

    private _getMenuItemSets(nProject: number, oProducts: IProjectProduct[]): string[] {

        let oSets: string[] = [];

        oProducts.forEach((oProduct: IProjectProduct) => {
            const sProduct: string = oProduct.type.split("_")[0];
            oSets.push("set-" + sProduct);
        });

        oSets = [...new Set(oSets)];

        switch (oSets.length) {
            case 1:
                break;
            case 0:
                this.printWarning("_getMenuItemSets", "No product set found for project " + nProject + ".");
                oSets.push(Guide3DMenuService.MENU_TYPE_START);
                break;
            default:
                this.printWarning("_getMenuItemSets", "More than one product sets (=" + oSets + ") found for project " + nProject + ".");
                break;
        }

        return oSets;

    }

}

