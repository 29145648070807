import React, { useCallback } from 'react';
/* import { styled } from '@mui/material/styles'; */
/* import Box from '@mui/material/Box'; */
/* import Paper from '@mui/material/Paper'; */
/* import Grid from '@mui/material/Unstable_Grid2'; */
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UpdateIcon from '@mui/icons-material/Update';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MonitorIcon from '@mui/icons-material/Monitor';
import BarChartIcon from '@mui/icons-material/BarChart';
import DescriptionIcon from '@mui/icons-material/Description';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PrintIcon from '@mui/icons-material/Print';
import CodeIcon from '@mui/icons-material/Code';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import LanguageIcon from '@mui/icons-material/Language';
import PlaceIcon from '@mui/icons-material/Place';
import LaptopIcon from '@mui/icons-material/Laptop';
import Button from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from './hooks';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import { Box, SxProps, Typography } from '@mui/material';
import { setCurrentProjectsController_branches, setCurrentProjectsController_detailDisplay } from './slices/projectsControllerSlice';
import { useTranslation } from 'react-i18next';
import xlsx from "json-as-xlsx"

interface IConverterProject {
  "project": number;
  "public": boolean;
  "service": boolean;
  "name": string;
  "city": string;
  "country": string;
  "languages": IProjectLanguages;
  "industry": string;
  "reference": string;
  "dataConnection": boolean;
  "products": IProjectProduct[];
  "productsOptions": IProjectProductsOption[];
  "validated": boolean;
}

interface IProjectLanguages {
  [code: string]: string;
}

interface IProjectProduct {
  "name": string;
  "type": "easyGuide_kiosk" | "easyGuide_mobile" | "easyGuide_web" | "easyGuide_print" | "easyMaps_mobile" | "easyMaps_kiosk" | "carFinder" | "mapsEngine" | "custom" | "endOfLife";
  "onPremises": boolean;
  "links"?: {
    "url": string;
    "app"?: string;
    "description"?: string;
    "status"?: string;
    "kioskList"?: string;
    "mobileSimulator"?: string;
    "webConfigurator"?: string;
    "qrCodeGenerator"?: string;
  };
  "kioskPlatform"?: string;
}

interface IProjectProductsOption {
  "name": string;
  "type": "add-on" | "tool" | "deployment";
  "links"?: {
    "description"?: string;
  };
}

interface IExcelLabelObject {
  label: string;
  value: string;
}

interface IExcelData {
  sheet: string;
  columns: IExcelLabelObject[],
  content: any[];
}

let data: IExcelData[] = [
  {
    sheet: "Projects",
    columns: [
      { label: "Project ID", value: "project_id" },
      { label: "Name", value: "name" },
      { label: "Validated", value: "validated" },
      { label: "Public", value: "public" },
      { label: "Service", value: "service" },
      { label: "Data connection", value: "data_connection" },
      { label: "City", value: "city" },
      { label: "Country", value: "country" },
      { label: "Languages", value: "languages" },
      { label: "Industry", value: "industry" },
      { label: "Reference", value: "reference" },
      { label: "Products", value: "products" }, // name / type
      { label: "productsOptions", value: "productsOptions" }, // name / type
      { label: "Keywords", value: "keywords" }
    ],
    content: [

    ],
  }
]

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000000",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
    maxWidth: 220,
    fontSize: "1rem",
  },
}));

export default function FullWidthGrid() {
  //setCurrentProjectsController_guiInternStatus
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const guiInternStatus = useAppSelector((state) => state.projectsController.value.guiInternStatus);
  const currentProjects = useAppSelector((state) => state.projectsController.value.currentProjects);
  const allProjects = useAppSelector((state) => state.projectsController.value.allProjects);
  const searchTerm = useAppSelector((state) => state.projectsController.value.sTerm);
  const currentBranch = useAppSelector((state) => state.projectsController.value.currentBranch);
  const detailProject: IGuide3DMenuServiceCommonInterfacesPointItemElementType | undefined = useAppSelector((state) => state.projectsController.value.detailProject);
  const bIntern = useAppSelector((state) => state.projectsController.value.bIntern);
  const prodButtonSx: SxProps = { marginRight: "0.3em", marginBottom: "0.4em", marginTop: "0.0em", minWidth: "12.1em", height: "1.7em", textTransform: 'none' };
  const copyIconSx: SxProps = { cursor: "pointer", fontSize: "1em", marginLeft: "0.0em", marginRight: "0.6em", color: "#000000" };
  /* let mProjMap: Map<string, IConverterProject> = new Map(); */

  const [fill, setFill] = React.useState(false);
  const [allMap, setallMap] = React.useState(new Map());
  const [firstInit, setFirstInit] = React.useState(true);
  const [detailDisplay, setDetailDisplay] = React.useState(false);
  /* const [detailString, setDetailString] = React.useState(""); */
  const [detailElement, setDetailElement] = React.useState({
    "id": "",
    "type": "",
    "display": false,
    "name": "",
    "name-label": "",
    "description": "",
    "description-label": "",
    "icon": "",
    "data": "",
    "default": false,
    "keywords": [""],
    "keywords-label": "",
    "url": "",
    "point": "",
    "location": [""],
    "location-label": "",
    "redirect": [""],
    "grouping-tag": "",
    "grouping-action": "",
    sets: [""],
    html: "",
  } as IGuide3DMenuServiceCommonInterfacesPointItemElementType);


  const handleExcelDownloadClick = () => {
    const cD: Date = new Date();
    const dateString: string = cD.getFullYear() + '-' + ('0' + (cD.getMonth() + 1)).slice(-2) + '-' + ('0' + cD.getDate()).slice(-2) + "T" + ('0' + (cD.getHours() + 1)).slice(-2) + "-" + ('0' + cD.getMinutes()).slice(-2) + "-" + ('0' + cD.getSeconds()).slice(-2);

    let settings = {
      fileName: ("ProjectsData_" + dateString), // Name of the resulting spreadsheet
      //extraLength: 3, // A bigger number means that columns will be wider
      writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
      RTL: false, // Display the columns from right-to-left (the default value is false)
    }

    xlsx(data, settings) // Will download the excel file
  }

  const handleItemClick = useCallback((dataString: string, element: IGuide3DMenuServiceCommonInterfacesPointItemElementType) => {
    setFill(false);
    /* setDetailString(dataString); */
    console.log("JSON: ", JSON.parse(dataString));
    console.log("element: ", element);
    setDetailElement(element)
    dispatch(setCurrentProjectsController_detailDisplay(true));
    setDetailDisplay(true);




    window.scrollTo(0, 0);
  }, [dispatch]);

  const handleBackClick = () => {
    setDetailDisplay(false);
    setFill(true);
    /* setDetailString(""); */
    dispatch(setCurrentProjectsController_detailDisplay(false));
    window.scrollTo(0, 0);
    window.setTimeout(() => {
      const inputEl = window.document.getElementById("mainInput") as HTMLInputElement;
      inputEl.value = searchTerm;
    }, 250);
    /* setDetailElement(element) */
  }

  const handleProdDescriptionButtonClick = (prodName: string, elementData: string = "none", dryrun: boolean = false): string | undefined => {
    let constructedLink: string = "";
    switch (prodName) {
      case "easyGuide kiosk":
        if (elementData !== "none") {
          const branch: string = extractBranch(elementData, true);
          if (branch === "Shopping Malls & Retail" || branch === "Einkaufszentren & Einzelhandel") {
            constructedLink = 'https://3d-berlin.com/produkte-de/easyguide/easyguide-kiosk-retail/';
          }
          else {
            constructedLink = 'https://3d-berlin.com/produkte-de/easyguide/easyguide-kiosk-business/';
          }
        }
        break;
      case "easyGuide web":
        constructedLink = 'https://3d-berlin.com/produkte-de/easyguide/easyguide-web/';
        break;
      case "easyGuide mobile":
        constructedLink = 'https://3d-berlin.com/produkte-de/easyguide/easyguide-mobile/';
        break;
      case "mapsEngine":
        constructedLink = 'https://3d-berlin.com/produkte-de/mapsengine/';
        break;
      case "easyGuide print":
        constructedLink = 'https://3d-berlin.com/produkte-de/easyguide/easyguide-print/';
        break;
      case "carFinder":
        constructedLink = 'https://3d-berlin.com/produkte-de/carfinder/';
        break;
      case "easyMaps mobile":
        constructedLink = 'https://3d-berlin.com/produkte-de/easymaps/';
        break;
      case "easyMaps kiosk":
        constructedLink = 'https://3d-berlin.com/produkte-de/easymaps/';
        break;
      default:
        break;
    }

    if (dryrun === false) {
      window.open(constructedLink, '_blank');
    }
    else {
      return constructedLink;
    }
  }


  const handleProjDescriptionButtonClick = (elementId: string, dryrun: boolean = false): string | undefined => {
    /* const prefix: string = "https://3d-berlin.com/" */
    const oJson: IConverterProject = allMap.get(elementId) as IConverterProject;
    /* console.log("extractName id:" + elementId);   
    console.log("extractName oJson:", oJson);   */

    let constructedLink: string = "";
    /*  let nameString: string = extractName(elementId, true);
     nameString = nameString.toLowerCase();
     nameString = nameString.replaceAll("ece-", "");
     nameString = nameString.replaceAll("ece ", "");
     nameString = nameString.replaceAll(" ", "-"); */
    constructedLink = oJson.reference;
    if (dryrun === false) {
      window.open(constructedLink, '_blank');
    }
    else {
      return constructedLink;
    }
  }

  const setHighlightSpan = (nameString: string): string => {
    const regexpName: RegExp = new RegExp(searchTerm, "gi");
    const displayValueMatches: string[] = nameString.match(regexpName) as string[];
    let nameConstruct: string = nameString;
    const aDone: string[] = [];
    if (displayValueMatches) {
      /* console.log("displayValueMatches: " + nameConstruct); */
      for (const entry of displayValueMatches) {
        const regexp2Name: RegExp = new RegExp(entry, "g");
        if (aDone.indexOf(entry) === -1) {
          const first: string = entry.charAt(0);
          if (first === first.toLowerCase() && first !== first.toUpperCase()) {
            // first character is a lowercase letter
            const num: number = nameConstruct.indexOf(entry);
            const prePos: string = nameConstruct.charAt(num - 1);
            if (prePos === " " || prePos === "(" || prePos === ",") {
              nameConstruct = nameConstruct.replace(entry, ("<>" + entry + "</>"));
              /* nameConstruct.charAt(0) = "<>" + entry + "</>"; */

              aDone.push(entry);
            }

          } else {
            nameConstruct = nameConstruct.replace(regexp2Name, ("<>" + entry + "</>"));
            aDone.push(entry);
          }
        }
      }
      while (nameConstruct.indexOf("<>") >= 0) {
        nameConstruct = nameConstruct.replace("<>", "<span class='highlight'>");
        nameConstruct = nameConstruct.replace("</>", "</span>");
      }
    }
    /* console.log("nameConstruct: " + nameConstruct); */
    return nameConstruct;
  }

  const extractValidation = (element: string): boolean => {
    let bValidated: boolean = false;
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    if (bIntern === true) {
      if (oJson.validated === true) {
        bValidated = true;
      }
    }
    return bValidated;
  }

  const extractValidationAll = (element: string): boolean => {
    let bValidated: boolean = false;
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    if (oJson.validated === true) {
      bValidated = true;
    }
    return bValidated;
  }

  const extractName = (element: string, nameOnly: boolean = false): string => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* console.log("extractName id:" + element);   
    console.log("extractName oJson:", oJson);   */
    /* console.log("extractName => searchTerm: " + searchTerm); */
    let nameString: string = "";
    if (nameOnly === false) {
      if (bIntern === true) {
        nameString = oJson.project + ": " + oJson.name;
      }
      else {
        nameString = oJson.name;
      }
    }
    else {
      nameString = oJson.name;
    }
    if (searchTerm.length > 0 && nameOnly === false) {
      return setHighlightSpan(nameString);
    }

    return nameString;
  }

  const extractReference = (element: string): string => {
    //const oJson: IConverterProject = JSON.parse(element as string);
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    let referenceString: string = "";
    referenceString = oJson.reference;
    return referenceString;
  }

  const extractLocation = (element: string, nameOnly: boolean = false): string => {
    // const oJson: IConverterProject = JSON.parse(element as string);
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    let locString: string = "";
    locString = oJson.city + ", " + oJson.country;
    if (searchTerm.length > 0 && nameOnly === false) {
      return setHighlightSpan(locString);
    }
    return locString;
  }

  const extractBranch = (element: string, nameOnly: boolean = false): string => {
    // const oJson: IConverterProject = JSON.parse(element as string);
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    let branchString: string = "";
    branchString = oJson.industry;
    if (searchTerm.length > 0 && nameOnly === false) {
      return setHighlightSpan(branchString);
    }
    return branchString;
  }

  const hitinkeywords = (keywords: string[]): boolean => {
    let foundKeywords: boolean = false;
    const regexpName: RegExp = new RegExp(searchTerm, "gi");

    keywords.forEach(element => {
      const displayValueMatches: string[] = element.match(regexpName) as string[];
      if (displayValueMatches) {
        foundKeywords = true;
      }
    });
    return foundKeywords;
  }

  const extractKeywordHits = (keywords: string[]): string => {
    let constructedKeyString: string[] = [];
    const regexpName: RegExp = new RegExp(searchTerm, "gi");
    keywords.forEach(element => {
      const displayValueMatches: string[] = element.match(regexpName) as string[];
      if (displayValueMatches) {
        const str: string = setKeywordsSpan(element);
        if (str !== "") {
          constructedKeyString.push(str);
        }
      }
    });
    if (constructedKeyString.length > 0) {
      return ("(" + constructedKeyString.toString().replaceAll(",", ", ") + ")");
    }
    else {
      return "";
    }


  }

  const replaceAt = (str: string, index: number, replacement: string): string => {
    return str.substring(0, index) + replacement + str.substring(index + searchTerm.length);
  }

  const setKeywordsSpan = (nameString: string): string => {
    const regexpName: RegExp = new RegExp(searchTerm, "gi");
    const displayValueMatches: string[] = nameString.match(regexpName) as string[];
    let nameConstruct: string = nameString;
    let posCount: number = 0;
    const aDone: string[] = [];
    if (displayValueMatches) {
      /* console.log("displayValueMatches: " + displayValueMatches); */
      for (const entry of displayValueMatches) {
        /* const regexp2Name: RegExp = new RegExp(entry, "gi"); */
        const num: number = nameConstruct.indexOf(entry, posCount);
        /* console.log("nameString: " + nameString + " / num: " + num); */
        if (num === 0) {
          nameConstruct = nameConstruct.replace(entry, ("<>" + entry + "</>"));
          aDone.push(entry);
        }
        else {
          const prePos: string = nameConstruct.charAt(num - 1);
          if (prePos === " " || prePos === "(" || prePos === ",") {
            // nameConstruct = nameConstruct.replace(entry, ("<>" + entry + "</>"));
            nameConstruct = replaceAt(nameConstruct, num, "<>" + entry + "</>");

            aDone.push(entry);
          }
        }
        posCount = posCount + searchTerm.length;
      }
      if (nameConstruct === nameString) {
        nameConstruct = "";
      }
      else {
        /* console.log("nameConstruct: " +nameConstruct); */
        while (nameConstruct.indexOf("<>") >= 0) {
          nameConstruct = nameConstruct.replace("<>", "<span class='highlight'>");
          nameConstruct = nameConstruct.replace("</>", "</span>");
        }
      }
    }
    /* console.log("nameConstruct: " + nameConstruct); */
    return nameConstruct;
  }

  const extractProdnumber = (element: string): number => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let prodNumber: number = 0;
    prodNumber = oJson.products.length;
    return prodNumber;
  }

  const extractService = (element: string): boolean => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let bService: boolean = false;
    bService = oJson.service;
    return bService;
  }

  const extractVisibility = (element: string): boolean => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let bVisible: boolean = false;
    bVisible = oJson.public;
    return bVisible;
  }

  const extractDataconnection = (element: string): boolean => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let bDatacon: boolean = false;
    bDatacon = oJson.dataConnection;
    return bDatacon;
  }

  const extractonPremise = (element: string): boolean => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let bOnPremises: boolean = false;
    oJson.products.forEach(element => {
      if (element.onPremises === true) {
        bOnPremises = true
      }
    });
    return bOnPremises;
  }

  const extractProducts = (element: string): string[] => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let productsStrings: string[] = [];
    oJson.products.forEach(prod => {
      productsStrings.push(prod.name);
    });
    if (productsStrings.length === 0) {
      productsStrings.push(t('zeroprods'))
    }
    /*     console.log("productsStrings[0]: ", productsStrings[0]);
        console.log("extractValidation(element) : ", extractValidationAll(element)); */
    if (productsStrings[0].indexOf("End-of-life") >= 0 && extractValidationAll(element) === true) {
      productsStrings.push("easyGuide kiosk");
    }
    return productsStrings;
  }

  const extractEoL = (element: string): boolean => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    let bEoL: boolean = false;
    oJson.products.forEach(prod => {
      if (prod.name.indexOf("End-of-life") >= 0) {
        bEoL = true;
      }
    });
    return bEoL;
  }

  const extractProductsForExcel = (element: string): string[] => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let productsStrings: string[] = [];
    oJson.products.forEach(prod => {
      productsStrings.push("name: " + prod.name + " / type: " + prod.type);
    });
    if (productsStrings.length === 0) {
      productsStrings.push(t('zeroprods'))
    }
    return productsStrings;
  }

  const extractProductWebUrl = (element: string): string => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let webUrlString: string = "";
    oJson.products.forEach(element => {
      if (element.name === "easyGuide web") {
        webUrlString = element.links?.url as string;
      }
    });
    return webUrlString;
  }

  const extractCarfinderUrl = (element: string): string => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let carfinderUrlString: string = "";
    oJson.products.forEach(element => {
      console.log("extractCarfinderUrl ", element);
      if (element.name === "carFinder") {
        carfinderUrlString = element.links?.url as string;
      }
    });
    return carfinderUrlString;
  }

  const extractEasyMapsUrl = (element: string, linkType: string): string => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let easyMapsMobileUrlString: string = "";
    oJson.products.forEach(element => {
      console.log("extractEasyMapsUrl ", element);
      if (element.name === "easyMaps mobile") {
        switch (linkType) {
          case "url":
            easyMapsMobileUrlString = element.links?.url as string;
            break;
          case "app":
            easyMapsMobileUrlString = element.links?.app as string;
            break;
          case "description":
            easyMapsMobileUrlString = element.links?.description as string;
            break;
          default:
            break;
        }
      }
    });
    return easyMapsMobileUrlString;
  }

  const extractProductWebAppUrl = (element: string): string => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let webAppUrlString: string = "";
    oJson.products.forEach(element => {
      if (element.name === "easyGuide web") {
        webAppUrlString = element.links?.app as string;
      }
    });
    return webAppUrlString;
  }

  const extractLanguages = (element: string): string => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let langString: string = "";
    let languagesStrings: string[] = [];
    for (const [key, value] of Object.entries(oJson.languages)) {
      languagesStrings.push(value);
      if (key === "1") {
        // do nothing
      }
    }
    langString = (languagesStrings.toString()).replaceAll(",", ", ");
    return langString;
  }

  const bProductAddonsAvailable = (element: string): boolean => {
    let bAddonsPresent: boolean = false;
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    oJson.productsOptions.forEach(element => {
      if (element.type === 'add-on') {
        bAddonsPresent = true;
      }
    });
    return bAddonsPresent;
  }

  const extractAddons = (element: string): IProjectProductsOption[] => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let productsAddonsList: IProjectProductsOption[] = [];
    oJson.productsOptions.forEach(element => {
      if (element.type === 'add-on') {
        productsAddonsList.push(element);
      }
    });
    return productsAddonsList;
  }

  const extractAddonsForExcel = (element: string): string[] => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let productsAddonsList: string[] = [];
    oJson.productsOptions.forEach((element: IProjectProductsOption) => {
      /* if (element.type === 'add-on') { */
      productsAddonsList.push(element.name + " / type: " + element.type);
      /* } */
    });
    return productsAddonsList;
  }

  const bProductToolsAvailable = (element: string): boolean => {
    let bToolsPresent: boolean = false;
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    oJson.productsOptions.forEach(element => {
      if (element.type === 'tool') {
        bToolsPresent = true;
      }
    });
    return bToolsPresent;
  }

  const bQrGenToolAvailable = (element: string): boolean => {
    let bQrGenToolPresent: boolean = false;
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    oJson.productsOptions.forEach(element => {
      if (element.type === 'tool' && element.name === 'Tool: QR-Code-Generator') {
        bQrGenToolPresent = true;
      }
    });
    return bQrGenToolPresent;
  }

  const extractTools = (element: string): IProjectProductsOption[] => {
    const oJson: IConverterProject = allMap.get(element) as IConverterProject;
    /* const oJson: IConverterProject = JSON.parse(element as string); */
    let productsToolsList: IProjectProductsOption[] = [];
    oJson.productsOptions.forEach(element => {
      if (element.type === 'tool') {
        productsToolsList.push(element);
      }
    });
    return productsToolsList;
  }

  const randomIntFromInterval = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const handleClipboardCopy = (str: string) => {
    navigator.clipboard.writeText(str);
  }

  const _encodeString = (str: string): string => {
    const projectPastfix = str.substring(3);
    const randomPrefix = String(randomIntFromInterval(100, 999));
    const toEncode = Number(randomPrefix + projectPastfix);
    const encodedString = window.btoa(String(toEncode));

    return encodedString;
  }

  const _getAppString = (): string => {
    let appString: string = "Demo"
    if (bIntern === true) {
      appString = "Demo (live)"
    }
    return appString
  }

  const _getkioskBetaUrl = (id: string, elementData: string): string => {
    const branch: string = extractBranch(id, true);
    if (branch === "Shopping Malls & Retail" || branch === "Einkaufszentren & Einzelhandel") {
      return "http://beta.local:88/beta_apps/EasyGuideKioskRetail/" + id + "/";
    }
    else {
      return "http://beta.local:88/beta_apps/EasyGuideKioskBusiness-beta/" + id + "/";
    }
  }

  const _getTileItemClasses = (id: string): string => {
    let classes: string = "item"
    const branch: string = extractBranch(id as string);
    if (currentBranch !== "") {
      if (branch !== currentBranch) {
        classes = "item hidetile"
      }
    }
    return classes;
  }

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = "https://services.guide3d.com/images/references/reference.webp";
  };

  const _provideAllIndustries = () => {
    let branches: string[] = [];
    allProjects.forEach((element: IGuide3DMenuServiceCommonInterfacesPointItemElementType) => {
      const branch: string = extractBranch(element.id as string)
      if (branches.indexOf(branch) === -1) {
        branches.push(branch);
      }
    });
    dispatch(setCurrentProjectsController_branches(branches.sort()));
  }

  React.useEffect(() => {
    /* console.log("guiInternStatus: " + guiInternStatus); */
  }, [guiInternStatus]);

  React.useEffect(() => {
    // console.log("currentBranch selected for Grid: " + currentBranch);
  }, [currentBranch]);


  React.useEffect(() => {
    /* console.log("detailProject: " + detailProject); */
    if (detailProject !== undefined) {
      handleItemClick(detailProject.data as string, detailProject)
    }
  }, [detailProject, handleItemClick]);


  React.useEffect(() => {
    /* console.log("allProjects.length: " + allProjects.length);
    console.log("firstInit: " + firstInit); */
    if (firstInit === true && allProjects.length > 0) {
      setFirstInit(false);
      /* console.log("currentProjects", currentProjects); */
      allProjects.forEach((element: IGuide3DMenuServiceCommonInterfacesPointItemElementType) => {
        const oJson: IConverterProject = JSON.parse(element.data as string) as IConverterProject;
        setallMap(allMap.set(element.id, oJson));
        /* console.log("element.id Json set: " + element.id); */
      });
      _provideAllIndustries();
      window.setTimeout(() => {
        /* console.log("WTF DU PENNER!!!" + allMap.size);
        console.log("detailProject => " + detailProject); */

        currentProjects.forEach((element: IGuide3DMenuServiceCommonInterfacesPointItemElementType) => {
          const oJson: IConverterProject = allMap.get(element.id) as IConverterProject;
          let entry = {
            project_id: oJson.project.toString(),
            name: oJson.name,
            validated: oJson.validated.toString(),
            public: oJson.public.toString(),
            service: oJson.service.toString(),
            data_connection: oJson.dataConnection.toString(),
            city: oJson.city,
            country: oJson.country,
            languages: extractLanguages(element.id),
            industry: oJson.industry,
            reference: oJson.reference,
            products: extractProductsForExcel(element.id).toString(),
            productsOptions: extractAddonsForExcel(element.id).toString(),
            keywords: element.keywords?.toString()
          }
          data[0].content.push(entry);
        });



        if (detailProject === undefined) {
          setFill(true);
        }
      }, 500);
    }

  }, [allProjects, firstInit, detailProject, allMap]);
  return (
    <div className='item-container'>
      {bIntern ?
        <Button
          variant="contained"
          sx={{ width: '98.5%', mt: '1rem', mb: '1rem', zIndex: 0 }}
          onClick={handleExcelDownloadClick}
        >Download data-table</Button>
        : null}
      {fill
        ?
        currentProjects.map((element: IGuide3DMenuServiceCommonInterfacesPointItemElementType) => {
          return <Box id={"tileitem_" + element.id}
            onClick={() => handleItemClick(element.data as string, element)}
            key={element.id} className={_getTileItemClasses(element.id as string)}
            sx={{
              ...(extractValidation(element.id as string) === true && {
                borderLeft: '4px solid #C9D30F'
              })
            }}
          >
            <img src={element.icon} alt={element.name + " Logo"} />
            <div className='item-textcontainer'>
              <span className='item-heading' dangerouslySetInnerHTML={{ __html: extractName(element.id as string) }}>
              </span>
              <span className='item-location' dangerouslySetInnerHTML={{ __html: extractLocation(element.id as string) }}></span>
              {element.location
                ?
                <span className='item-branch' dangerouslySetInnerHTML={{ __html: extractBranch(element.id as string) }}></span>
                : null
              }
              {searchTerm.length > 0 && hitinkeywords(element.keywords as string[])
                ?
                <span className='item-keywords' dangerouslySetInnerHTML={{ __html: extractKeywordHits(element.keywords as string[]) }}></span>
                : null
              }
            </div>
            <div className='item-sidecontainer'>
              <HtmlTooltip key={"tooltip_" + element.id + "_A1"} placement="right"
                title={
                  <React.Fragment>
                    {extractProducts(element.id as string).map((text) => (
                      <Typography key={"typo_" + element.id + text + "_B1"} color="inherit">{text}</Typography>
                    ))
                    }
                  </React.Fragment>
                }
              >
                <div className='div-prodnumber'>
                  <span className='item-prodnumber'>{(extractProdnumber(element.id as string))}</span>
                </div>
              </HtmlTooltip>
              {extractVisibility(element.id as string) && bIntern
                ?
                <HtmlTooltip key={"tooltip_" + element.id + "_C1"} placement="right"
                  title={
                    <React.Fragment>
                      <Typography key={"visible_" + element.id + "_D1"} color="inherit">{t('visible')}</Typography>
                    </React.Fragment>
                  }
                >
                  <VisibilityIcon className='sideIcons' sx={{ marginRight: "0.15em", color: "inherit" }}></VisibilityIcon>
                </HtmlTooltip>
                : null
              }
              {extractService(element.id as string) && bIntern
                ?
                <HtmlTooltip key={"tooltip_" + element.id} placement="right"
                  title={
                    <React.Fragment>
                      <Typography key={"service_" + element.id + "_E1"} color="inherit">{t('service')}</Typography>
                    </React.Fragment>
                  }
                >
                  <UpdateIcon className='sideIcons' sx={{ marginRight: "0.15em", color: "inherit" }}></UpdateIcon>
                </HtmlTooltip>
                : null
              }
            </div>
          </Box>
        })
        : null
      }
      {detailDisplay
        ?
        <div className='detailcontainer'>
          <div className='detail_back_container' onClick={handleBackClick}>
            <ArrowBackIcon className='back_arrow' sx={{ fontSize: "4rem", marginRight: "0.2em", color: "#000000" }}></ArrowBackIcon>
          </div>
          <div className='detail_toprow'>
            <div className='detail_basics_container'>
              <img src={detailElement.icon} alt={detailElement.name + " Logo"} />
              {bIntern
                ?
                <div>
                  <span className='detail-projnumber'>{detailElement.id} </span>
                  <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(detailElement.id)} sx={copyIconSx}></ContentCopyIcon>
                </div>
                : null
              }
              <div>
                <span className='detail-name'>{extractName(detailElement.id as string, true)}</span>
                {bIntern
                  ?
                  <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(extractName(detailElement.id as string, true))} sx={copyIconSx}></ContentCopyIcon>
                  : null
                }
              </div>
              <span className='detail-location'>{extractLocation(detailElement.id as string, true)}</span>
              {detailElement.location
                ?
                <span className='detail-branch'>{(extractBranch(detailElement.id as string, true))}</span>
                : null
              }
              <span className='detail-languages'>{extractLanguages(detailElement.id as string)}</span>
              {extractVisibility(detailElement.id as string)
                ?
                <Button className='prodbutton' sx={prodButtonSx} onClick={() =>
                  handleClipboardCopy("https://demo.3d-berlin.com/search/" + detailElement.id)
                } variant="contained" color="info" startIcon={<ContentCopyIcon className='prodIcon' />}>
                  {t('copylink')}
                </Button>
                : null
              }
            </div>
            {bIntern
              ?
              <div className='detail_flags_container'>
                <div className='flag'>
                  <div>
                    <VisibilityIcon className='sideIcons' sx={{ marginLeft: "0.3em", marginRight: "0.2em", marginTop: "0.1em", marginBottom: "0.1em", color: "#000000" }}></VisibilityIcon>
                    <span className='flag-name'>Visible</span>
                  </div>
                  {extractVisibility(detailElement.id as string)
                    ?
                    <CheckIcon className='sideIcons' sx={{ marginRight: "0.2em", color: "#0b930b" }}></CheckIcon>
                    :
                    <CloseIcon className='sideIcons' sx={{ marginRight: "0.2em", color: "#ff0000" }}></CloseIcon>
                  }
                </div>
                <div className='flag'>
                  <div>
                    <UpdateIcon className='sideIcons' sx={{ marginLeft: "0.3em", marginRight: "0.2em", marginTop: "0.1em", marginBottom: "0.1em", color: "#000000" }}></UpdateIcon>
                    <span className='flag-name'>Service</span>
                  </div>
                  {extractService(detailElement.id as string)
                    ?
                    <CheckIcon className='sideIcons' sx={{ marginRight: "0.2em", color: "#0b930b" }}></CheckIcon>
                    :
                    <CloseIcon className='sideIcons' sx={{ marginRight: "0.2em", color: "#ff0000" }}></CloseIcon>
                  }
                </div>
                <div className='flag'>
                  <div>
                    <UploadFileIcon className='sideIcons' sx={{ marginLeft: "0.3em", marginRight: "0.2em", marginTop: "0.1em", marginBottom: "0.1em", color: "#000000" }}></UploadFileIcon>
                    <span className='flag-name'>Data-Connection</span>
                  </div>
                  {extractDataconnection(detailElement.id as string)
                    ?
                    <CheckIcon className='sideIcons' sx={{ marginRight: "0.2em", color: "#0b930b" }}></CheckIcon>
                    :
                    <CloseIcon className='sideIcons' sx={{ marginRight: "0.2em", color: "#ff0000" }}></CloseIcon>
                  }
                </div>
                <div className='flag'>
                  <div>
                    <HouseSidingIcon className='sideIcons' sx={{ marginLeft: "0.3em", marginRight: "0.2em", marginTop: "0.1em", marginBottom: "0.1em", color: "#000000" }}></HouseSidingIcon>
                    <span className='flag-name'>On-Premise</span>
                  </div>
                  {extractonPremise(detailElement.id as string) //CloseIcon
                    ?
                    <CheckIcon className='sideIcons' sx={{ marginRight: "0.2em", color: "#0b930b" }}></CheckIcon>
                    :
                    <CloseIcon className='sideIcons' sx={{ marginRight: "0.2em", color: "#ff0000" }}></CloseIcon>
                  }
                </div>
              </div>
              : null
            }
            <div className='detail-reference-container'>
              <img src={"https://services.guide3d.com/images/references/" + detailElement.id + ".webp"} alt="" onError={imageOnErrorHandler} />
              {/* <Link href="#">Projektbeschreibung</Link> */}
              {extractReference(detailElement.id as string).length > 1
                ?
                <div className='detail-reference-subcontainer'>
                  <Button className='prodbutton' sx={{ marginRight: "0.0em", marginTop: "0.0em", height: "1.7em", width: "100%", textTransform: 'none' }} onClick={() => handleProjDescriptionButtonClick(detailElement.id as string)} variant="contained" color="info" startIcon={<DescriptionIcon className='prodIcon' />}>
                    {t('proj_descrpt')}
                  </Button>
                  {bIntern
                    ?
                    <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() =>
                      handleClipboardCopy(handleProjDescriptionButtonClick(detailElement.id as string, true) as string)
                    } sx={{ cursor: "pointer", fontSize: "1em", marginLeft: "0.4em", marginRight: "0.0em", color: "#000000" }}></ContentCopyIcon>
                    : null
                  }
                </div>
                : null
              }
            </div>
          </div>
          <div className='detail_bottom'>
            <div className='detail_bottom_products'>
              <span className='detail_bottom_heading'>{t('products')}</span>
              {extractProducts(detailElement.id as string).map((text) => (
                <div key={text + detailElement.id + "_F1"} className='detail_bottom_subContainer'>
                  <div className='detail_bottom_subContainer_text'>
                    <span className='detail_bottom_subheading'>{text}</span>
                    {bIntern
                      ?
                      <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(text)} sx={copyIconSx}></ContentCopyIcon>
                      : null
                    }
                  </div>
                  <div className='detail_bottom_button_container'>
                    {text === "easyGuide kiosk"
                      ?
                      <div className='detail_bottom_button_container'>
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                          window.open('https://app.guide3d.com/' + detailElement.id + '/kiosk', '_blank');
                        }} variant="contained" color="info" startIcon={<MonitorIcon className='prodIcon' />}>
                          {_getAppString()}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy('https://app.guide3d.com/' + detailElement.id + '/kiosk')} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        {extractEoL(detailElement.id) === false
                          ?
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
                            {bIntern
                              ?
                              <Button className='prodbutton' sx={prodButtonSx} onClick={() => { //_kioskBetaUrlExists
                                window.open(_getkioskBetaUrl(detailElement.id, detailElement.data as string), '_blank');
                              }} variant="contained" color="info" startIcon={<InsertLinkIcon className='prodIcon' />}>
                                Demo (beta)
                              </Button>
                              : null
                            }
                            {bIntern
                              ?
                              <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(_getkioskBetaUrl(detailElement.id, detailElement.data as string))} sx={copyIconSx}></ContentCopyIcon>
                              : null
                            }
                            <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                              window.open('https://app.guide3d.com/' + detailElement.id + '/kiosk/list/index.php', '_blank');
                            }} variant="contained" color="info" startIcon={<PlaceIcon className='prodIcon' />}>
                              {t('startpoints')}
                            </Button>
                            {bIntern
                              ?
                              <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy('https://app.guide3d.com/' + detailElement.id + '/kiosk/list/index.php')} sx={copyIconSx}></ContentCopyIcon>
                              : null
                            }
                            {bIntern
                              ?
                              <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                                window.open('http://services.guide3d.com/logger/mobile/?token=' + (_encodeString(detailElement.id)), '_blank');
                              }} variant="contained" color="info" startIcon={<BarChartIcon className='prodIcon' />}>
                                Status-Monitor
                              </Button>
                              : null
                            }
                            {bIntern
                              ?
                              <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy('http://services.guide3d.com/logger/mobile/?token=' + (_encodeString(detailElement.id)))} sx={copyIconSx}></ContentCopyIcon>
                              : null
                            }
                          </Box>
                          : null
                        }

                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => handleProdDescriptionButtonClick(text, detailElement.id as string)} variant="contained" color="info" startIcon={<DescriptionIcon className='prodIcon' />}>
                          {t('descrpt')}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(handleProdDescriptionButtonClick(text, detailElement.id as string, true) as string)} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                      </div>
                      : null
                    }
                    {text === "easyGuide web"
                      ?
                      <div className='detail_bottom_button_container'>
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                          window.open(extractProductWebUrl(detailElement.id as string), '_blank');
                        }} variant="contained" color="info" startIcon={<LanguageIcon className='prodIcon' />}>
                          Website
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(extractProductWebUrl(detailElement.id as string))} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                          window.open(extractProductWebAppUrl(detailElement.id as string), '_blank');
                        }} variant="contained" color="info" startIcon={<LaptopIcon className='prodIcon' />}>
                          {_getAppString()}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(extractProductWebAppUrl(detailElement.id as string))} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                          window.open("https://app.guide3d.com/" + detailElement.id + "/web-configurator/", '_blank');
                        }} variant="contained" color="info" startIcon={<TuneIcon className='prodIcon' />}>
                          Web-Configurator
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy("https://app.guide3d.com/" + detailElement.id + "/web-configurator/")} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        {bIntern
                          ?
                          <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                            window.open("http://beta2.local/beta_apps/projects/" + detailElement.id + "/web/?project=" + detailElement.id + "&mobile=true&print=true&menu=start-end", '_blank');
                          }} variant="contained" color="info" startIcon={<LaptopIcon className='prodIcon' />}>
                            Demo (Beta)
                          </Button>
                          : null
                        }
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy("http://beta2.local/beta_apps/projects/" + detailElement.id + "/web/?project=" + detailElement.id + "&mobile=true&print=true&menu=start-end")} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => handleProdDescriptionButtonClick(text)} variant="contained" color="info" startIcon={<DescriptionIcon className='prodIcon' />}>
                          {t('descrpt')}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(handleProdDescriptionButtonClick(text, "none", true) as string)} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                      </div>
                      : null
                    }
                    {text === "easyGuide mobile"
                      ?
                      <div className='detail_bottom_button_container'>
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                          window.open('https://www.easy-guide.mobi/simulator/index.php?project=' + detailElement.id, '_blank');
                        }} variant="contained" color="info" startIcon={<PhoneAndroidIcon className='prodIcon' />}>
                          Mobile Simulator
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy('https://www.easy-guide.mobi/simulator/index.php?project=' + detailElement.id)} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                          window.open('https://app.guide3d.com/' + detailElement.id + '/mobile/', '_blank');
                        }} variant="contained" color="info" startIcon={<InsertLinkIcon className='prodIcon' />}>
                          {_getAppString()}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy('https://app.guide3d.com/' + detailElement.id + '/mobile/')} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        {bIntern
                          ?
                          <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                            window.open('http://beta2.local/beta_apps/projects/' + detailElement.id + '/mobile/', '_blank');
                          }} variant="contained" color="info" startIcon={<InsertLinkIcon className='prodIcon' />}>
                            Demo (Beta)
                          </Button>
                          : null
                        }
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy('http://beta2.local/beta_apps/projects/' + detailElement.id + '/mobile/')} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        {bQrGenToolAvailable(detailElement.id as string) === true
                          ?
                          <Button className='prodbutton' sx={prodButtonSx} onClick={() => { //https://app.guide3d.com/100141/qr-code-generator/
                            window.open('https://app.guide3d.com/' + detailElement.id + '/qr-code-generator/', '_blank');
                          }} variant="contained" color="info" startIcon={<QrCode2Icon className='prodIcon' />}>
                            QR-Code-Generator
                          </Button>
                          : null
                        }
                        {bIntern && bQrGenToolAvailable(detailElement.id as string) === true
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy('https://app.guide3d.com/' + detailElement.id + '/qr-code-generator/')} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => handleProdDescriptionButtonClick(text)} variant="contained" color="info" startIcon={<DescriptionIcon className='prodIcon' />}>
                          {t('descrpt')}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(handleProdDescriptionButtonClick(text, "none", true) as string)} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                      </div>
                      : null
                    }
                    {text === "easyMaps mobile"
                      ?
                      <div className='detail_bottom_button_container'>
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => { //extractCarfinderUrl
                          window.open(extractEasyMapsUrl(detailElement.id as string, "url"), '_blank');
                        }} variant="contained" color="info" startIcon={<InsertLinkIcon className='prodIcon' />}>
                          {_getAppString()}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => extractEasyMapsUrl(detailElement.id as string, "url")} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => handleProdDescriptionButtonClick(text)} variant="contained" color="info" startIcon={<DescriptionIcon className='prodIcon' />}>
                          {t('descrpt')}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(handleProdDescriptionButtonClick(text, "none", true) as string)} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                      </div>
                      : null
                    }
                    {text === "mapsEngine"
                      ?
                      <div className='detail_bottom_button_container'>
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                          window.open('https://engine.easy-guide.com/example.html?project=' + detailElement.id + '&debug=false&end=', '_blank');
                        }} variant="contained" color="info" startIcon={<CodeIcon className='prodIcon' />}>
                          {_getAppString()}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy('https://engine.easy-guide.com/example.html?project=' + detailElement.id + '&debug=false&end=')} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        {bIntern
                          ?
                          <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                            window.open("http://beta.local:88/beta_apps/EasyGuideMapsEngine-beta/" + detailElement.id + "/engine/", '_blank');
                          }} variant="contained" color="info" startIcon={<InsertLinkIcon className='prodIcon' />}>
                            Demo (Beta)
                          </Button>
                          : null
                        }
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy("http://beta.local:88/beta_apps/EasyGuideMapsEngine-beta/" + detailElement.id + "/engine/")} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                          window.open('https://engine.easy-guide.com/?project=' + detailElement.id, '_blank');
                        }} variant="contained" color="info" startIcon={<CodeIcon className='prodIcon' />}>
                          {"Developer Guide"}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy('https://engine.easy-guide.com/?project=' + detailElement.id)} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => handleProdDescriptionButtonClick(text)} variant="contained" color="info" startIcon={<DescriptionIcon className='prodIcon' />}>
                          {t('descrpt')}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(handleProdDescriptionButtonClick(text, "none", true) as string)} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                      </div>
                      : null
                    }
                    {text === "easyGuide print"
                      ?
                      <div className='detail_bottom_button_container'>
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                          window.open('https://app.guide3d.com/' + detailElement.id + '/print/', '_blank');
                        }} variant="contained" color="info" startIcon={<PrintIcon className='prodIcon' />}>
                          {_getAppString()}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy('https://app.guide3d.com/' + detailElement.id + '/print/')} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        {bIntern
                          ?
                          <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                            window.open("http://beta2.local/beta_apps/projects/" + detailElement.id + "/print/", '_blank');
                          }} variant="contained" color="info" startIcon={<InsertLinkIcon className='prodIcon' />}>
                            Demo (Beta)
                          </Button>
                          : null
                        }
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy("http://beta2.local/beta_apps/projects/" + detailElement.id + "/print/")} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => handleProdDescriptionButtonClick(text)} variant="contained" color="info" startIcon={<DescriptionIcon className='prodIcon' />}>
                          {t('descrpt')}
                        </Button>
                        {bIntern
                          ?
                          <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(handleProdDescriptionButtonClick(text, "none", true) as string)} sx={copyIconSx}></ContentCopyIcon>
                          : null
                        }
                      </div>
                      : null
                    }
                    {text === "carFinder"
                      ?
                      <div className='detail_bottom_button_container'>
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => { //extractCarfinderUrl
                          window.open(extractCarfinderUrl(detailElement.id as string), '_blank');
                        }} variant="contained" color="info" startIcon={<InsertLinkIcon className='prodIcon' />}>
                          {_getAppString()}
                        </Button>
                        <Button className='prodbutton' sx={prodButtonSx} onClick={() => handleProdDescriptionButtonClick(text)} variant="contained" color="info" startIcon={<DescriptionIcon className='prodIcon' />}>
                          {t('descrpt')}
                        </Button>
                      </div>
                      : null
                    }
                  </div>
                </div>
              ))}
            </div>
            {bProductAddonsAvailable(detailElement.id as string) === true
              ?
              <div className='detail_bottom_addons'>
                <span className='detail_bottom_heading'>Add-Ons</span>
                {extractAddons(detailElement.id as string).map((addon, index) => (
                  <div key={"addon_" + detailElement.id + "_" + index} className='detail_bottom_subContainer'>
                    <div className='detail_bottom_subContainer_text'>
                      <span className='detail_bottom_subheading'>{addon.name}</span>
                      {bIntern
                        ?
                        <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(addon.name)} sx={copyIconSx}></ContentCopyIcon>
                        : null
                      }
                    </div>
                    <div className='detail_bottom_button_container'>
                      <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                        window.open(addon.links?.description as string, '_blank');
                      }} variant="contained" color="info" startIcon={<DescriptionIcon className='prodIcon' />}>
                        {t('descrpt')}
                      </Button>
                      {bIntern
                        ?
                        <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(addon.links?.description as string)} sx={copyIconSx}></ContentCopyIcon>
                        : null
                      }
                    </div>
                  </div>
                ))}
              </div>
              : null
            }
            {bProductToolsAvailable(detailElement.id as string) === true
              ?
              <div className='detail_bottom_tools'>
                <span className='detail_bottom_heading'>Tools</span>
                {extractTools(detailElement.id as string).map((tool) => (
                  <div key={"tool_" + detailElement.id + "_H1"} className='detail_bottom_subContainer'>
                    <div className='detail_bottom_subContainer_text'>
                      <span className='detail_bottom_subheading'>{tool.name}</span>
                      {bIntern
                        ?
                        <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(tool.name)} sx={copyIconSx}></ContentCopyIcon>
                        : null
                      }
                    </div>
                    <div className='detail_bottom_button_container'>
                      <Button className='prodbutton' sx={prodButtonSx} onClick={() => {
                        window.open(tool.links?.description as string, '_blank');
                      }} variant="contained" color="info" startIcon={<DescriptionIcon className='prodIcon' />}>
                        {t('descrpt')}
                      </Button>
                      {bIntern
                        ?
                        <ContentCopyIcon titleAccess={t('copytoclip') as string} onClick={() => handleClipboardCopy(tool.links?.description as string)} sx={copyIconSx}></ContentCopyIcon>
                        : null
                      }
                    </div>
                  </div>
                ))}
              </div>
              : null
            }

            {/* <div className='detail_bottom_description'>
              <span className='detail_bottom_heading'>Projektbeschreibung</span>
            </div> */}
          </div>
        </div>
        : null
      }
    </div>
  );
}

