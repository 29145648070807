import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import projectsControllerReducer from "./slices/projectsControllerSlice";
/* import guiControllerReducer from './slices/guiControllerSlice';
import roleStatusReducer from './slices/currentRoleSlice'
import currentAdminEditUserReducer from './slices/currentAdminEditUserSlice'
import currentAdminEditProjectReducer from './slices/currentAdminEditProjectSlice'; */

export const store = configureStore({
  reducer: {
    projectsController: projectsControllerReducer,
    /* rolestatus: roleStatusReducer,
    currentAdminEditUser: currentAdminEditUserReducer,
    currentAdminEditProject: currentAdminEditProjectReducer */
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;